import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../atoms/Loader';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import { PARemovePayload, removePAUser } from '../../../services/UsersService';

export interface PARemoveDialogProps {
  selectedRows: any;
  allRows: any;
  details: any;
  action: string;
  onSuccess?: Function;
  open: boolean;
  onChange?: Function;
}

const PARemoveDialog = ({
  selectedRows,
  allRows = [],
  details,
  action,
  onSuccess,
  open,
  onChange
}: PARemoveDialogProps) => {
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType } = authData;
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [remark, setRemark] = useState('');
  const [validation, setValidation] = useState<{
    remark?: string;
    primaryAccount?: string;
  }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const checkPrimaryAccount = () => {
    if (allRows?.length === selectedRows?.length) {
      return false;
    } else {
      const primaryAccountFoundInAll = allRows?.find((item: any) => {
        return item?.companyId === details?.companyId;
      });
      const primaryAccountFoundInSelected = selectedRows?.find((item: any) => {
        return item?.companyId === details?.companyId;
      });
      if (primaryAccountFoundInAll && primaryAccountFoundInSelected) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setConfirmDialogOpen(!!open);
  }, [open]);

  useEffect(() => {
    setStatusAction(action);

    setSubmitted(false);
  }, [action]);

  useEffect(() => {
    return () => {
      setRemark('');
    };
  }, [details]);

  useEffect(() => {
    checkValidation(true);
  }, [remark, statusAction]);

  useEffect(() => {
    checkValidation(true);
    return () => {
      setValidation({});
      setSubmitted(false);
    };
  }, [selectedRows]);

  const checkValidation = (isRemarkRequired = false) => {
    let errors: { remark?: string; primaryAccount?: string } = {
      ...validation
    };
    let remarkValue = remark?.trim();

    if (checkPrimaryAccount()) {
      errors.primaryAccount = `Partner Administrator privileges cannot be revoked from primary account (${details?.mcocompany}), unless it’s revoked from all the other company accounts.`;
    } else {
      delete errors.primaryAccount;
    }

    if (!remarkValue && isRemarkRequired) {
      errors.remark = 'Required';
    } else if (isRemarkRequired && remarkValue && remarkValue.length < 25) {
      errors.remark = 'Must be at least 25 characters';
    } else if (isRemarkRequired && remarkValue && remarkValue.length > 250) {
      errors.remark = "You can't write more than 250 characters";
    } else {
      delete errors.remark;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updateUser = async (
    action: string,
    userUpdateData: PARemovePayload
  ) => {
    if (checkValidation(true)) {
      try {
        setIsUpdateLoading(true);
        const result: any = await removePAUser(userUpdateData);
        setIsUpdateLoading(false);
        const responseStatus = result?.status?.toLowerCase();
        if (responseStatus === 'success') {
          notify(`User has been removed successfully`, 'success');
          if (onSuccess) {
            onSuccess(details);
          }
          setConfirmDialogOpen(false);
          if (onChange) onChange(false);
        } else {
          notify(
            `An error occurred while removing user, Please try again`,
            'error'
          );
        }
      } catch (error: any) {
        setIsUpdateLoading(false);
      }
    }
  };

  const updateInviteClick = (action: string) => {
    setSubmitted(true);
    const payloadData: PARemovePayload = {
      companyIdList:
        selectedRows && selectedRows?.length
          ? selectedRows?.map((item: any) => item?.companyId)
          : [],
      paUserId: details?.userId,
      paUserEmail: details?.email,
      paFirstName: details?.firstname,
      paLastName: details?.lastname,
      userType: actualUserType
        ? actualUserType?.map((item: any) => item?.toUpperCase())
        : [],
      remarks: remark ? remark?.trim() : ''
    };

    updateUser(action, payloadData);
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
    if (onChange) {
      onChange(false);
    }
  };

  const onRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(event.target.value);
  };

  const statusSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  return (
    <div className="">
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={!validation?.primaryAccount && `Confirm`}
        body={
          <div>
            <form name="statusform" onSubmit={statusSubmit}>
              {!validation?.primaryAccount && (
                <h4>
                  Are you sure to{' '}
                  <span>
                    {statusAction?.toLowerCase() === 'remove' ? (
                      <span>
                        remove the user as Partner Administrator from the below
                        companies?{' '}
                      </span>
                    ) : (
                      statusAction?.toLowerCase()
                    )}{' '}
                  </span>
                </h4>
              )}
              {validation?.primaryAccount && (
                <>
                  <div className="alert alert--warning dbl-margin-top">
                    <div className="alert__message">
                      {validation?.primaryAccount}
                    </div>
                  </div>
                </>
              )}
              {selectedRows && selectedRows?.length ? (
                <div
                  className="responsive-table base-margin-bottom"
                  style={{ maxHeight: '150px', overflow: 'auto' }}
                >
                  <table className="table table--bordered table--compressed">
                    <tbody>
                      {selectedRows?.map((item: any) => {
                        return (
                          <tr key={item?.companyId}>
                            <td>{item?.mcocompany}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}{' '}
              {!validation?.primaryAccount ? (
                <div>
                  <div
                    className={`form-group text-left ${
                      submitted && validation?.remark ? 'form-group--error' : ''
                    }`}
                  >
                    <div className="form-group__text">
                      <textarea
                        id="approver-comment"
                        rows={2}
                        value={remark || ''}
                        onChange={onRemarkChange}
                      ></textarea>
                      <label htmlFor="approver-comment">
                        <b>
                          Please provide a detailed justification{' '}
                          <span
                            className="text-danger"
                            title="this is a required field"
                          >
                            *
                          </span>
                          {submitted && validation?.remark && (
                            <>
                              <span className="text-danger text-size-12">
                                {' '}
                                {validation?.remark}
                              </span>
                            </>
                          )}
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {isUpdateLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}
            </form>
          </div>
        }
        footer={
          <div>
            {!validation?.primaryAccount ? (
              <>
                <button
                  type="button"
                  onClick={confirmDialogClose}
                  className="btn btn--ghost"
                  disabled={isUpdateLoading}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => updateInviteClick(statusAction)}
                  className="btn btn--primary"
                  disabled={isUpdateLoading}
                >
                  Confirm
                </button>
              </>
            ) : null}
          </div>
        }
      />
    </div>
  );
};
export default PARemoveDialog;
