import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RegisterUserCompany from '../RegisterUserCompany';
import FullScreenLoader from '../../molecules/FullScreenLoader';
// import { fetchUserActiveRequest, fetchProfile, getIsFederatedUser } from '../../../actions';
import DomainCheckWrapper from '../../molecules/DomainCheckWrapper';
import UserStatusCheckWrapper from '../../molecules/UserStatusCheckWrapper';
import UserProfile from '../../molecules/UserProfile';
import RequestStatus from '../RequestStatus';
import Dashboard from '../Dashboard';
import { config } from '../../../config/config';

const Home = () => {
  const { oneAccessUrl } = config;
  const [requestUserTypes, setRequestUserTypes] = useState<string[]>([]);
  // const dispatch = useDispatch();
  // const requestData = useSelector((state: any) => state?.request);
  const authData = useSelector((state: any) => state?.auth);
  const {
    mainUserType,
    userType,
    isFederatedUser,
    getIsFederatedUserStatus,
    getIsFederatedUserErrorMessage
  } = authData;
  // const { getUpdateRequestStatus } = requestData;

  const companyRegisterData = useSelector(
    (state: any) => state?.companyRegisterData
  );
  const {
    profile,
    getProfileStatus,
    getProfileErrorMessage,
    profileSubmitStatus
  } = companyRegisterData;

  useEffect(() => {
    setRequestUserTypes(userType || []);
  }, [userType]);

  // useEffect(() => {
  //   // dispatch(fetchUserActiveRequest());
  //   // dispatch(getIsFederatedUser());
  // }, []);

  // useEffect(() => {
  //   if (!(mainUserType === 'INTERNAL')) {
  //     // dispatch(getIsFederatedUser());
  //   }
  // }, [mainUserType]);

  // useEffect(() => {
  //   if (
  //     (requestUserTypes.includes('requester') ||
  //       requestUserTypes.includes('pa')) &&
  //     getUpdateRequestStatus !== 'SUCCESS'
  //   ) {
  //     // dispatch(fetchProfile());
  //   }
  // }, [requestUserTypes]);

  switch (true) {
    case getProfileStatus === 'PENDING' ||
      getIsFederatedUserStatus === 'PENDING':
      return <FullScreenLoader title={'Fetching user details'} />;

    case (getProfileStatus === 'FAILURE' && !!getProfileErrorMessage) ||
      (getIsFederatedUserStatus === 'FAILURE' &&
        !!getIsFederatedUserErrorMessage):
      return (
        <div className="base-margin-top">
          <div className="alert alert--warning">
            <div className="alert__message">
              {getProfileErrorMessage ? (
                <div>{getProfileErrorMessage}</div>
              ) : (
                ''
              )}
              {getIsFederatedUserErrorMessage ? (
                <div>{getIsFederatedUserErrorMessage}</div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );

    case (mainUserType === 'EXTERNAL' &&
      getProfileStatus === 'SUCCESS' &&
      getIsFederatedUserStatus === 'SUCCESS') ||
      mainUserType === 'INTERNAL': {
      return (
        <div>
          {mainUserType !== 'INTERNAL' && (
            <>
              {profile?.profileStatus?.toLowerCase() === 'active' ||
              profile?.profileStatus?.toLowerCase() === 'inactive' ||
              (profile?.profileStatus?.toLowerCase() === 'terminated' &&
                !profile?.rehire) ||
              requestUserTypes.includes('pa') ? (
                <UserProfile profile={profile} />
              ) : (
                <>
                  {requestUserTypes.includes('requester') &&
                    (profileSubmitStatus === 'SUCCESS' ||
                      (isFederatedUser &&
                        profile?.profileStatus?.toLowerCase() ===
                          'pending')) && (
                      <>
                        <div className="alert alert--success dbl-padding">
                          <div className="alert__message">
                            {isFederatedUser && (
                              <>
                                <h6>
                                  Your Supply Chain profile has been submitted
                                  successfully and is currently being processed.
                                </h6>
                                <h6>
                                  You will be notified through email once your
                                  profile is active.
                                </h6>
                              </>
                            )}
                            {!isFederatedUser && (
                              <>
                                <h6>
                                  Your Supply Chain profile creation request has
                                  been sent for approval.
                                </h6>
                                <h6>
                                  You will be notified through email, once it is
                                  approved.
                                </h6>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                  {requestUserTypes.includes('requester') &&
                    (!profile?.profileStatus ||
                      profile?.profileStatus?.toLowerCase() ===
                        'terminated') && (
                      // <UserStatusCheckWrapper profile={profile}>
                      <DomainCheckWrapper
                        checkDomain={
                          !(profile?.profileStatus?.toLowerCase() === 'active')
                        }
                      >
                        <RegisterUserCompany />
                      </DomainCheckWrapper>
                      // </UserStatusCheckWrapper>
                    )}
                </>
              )}
            </>
          )}
          {/* {mainUserType === 'INTERNAL' ? <Dashboard /> : null} */}
          {(requestUserTypes.includes('pa') ||
            requestUserTypes.includes('pam') ||
            (requestUserTypes.includes('requester') &&
              profile?.profileStatus?.toLowerCase() === 'pending' &&
              !isFederatedUser)) && <RequestStatus />}

          {mainUserType === 'INTERNAL' &&
          !requestUserTypes.includes('pa') &&
          !requestUserTypes.includes('pam') ? (
            <Dashboard />
          ) : null}
        </div>
      );
    }

    default:
      return <></>;
  }
};
export default Home;
