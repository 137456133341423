import React from 'react';

const ErrorShow = () => {
  return (
    <div className="vertical-center">
      <div className="container">
        <div className="section">
          <div className="text-center">
            <h1 className="text-size-96  text-weight-600 text-warning">
              <span className="icon-exclamation-triangle"></span>
            </h1>
            <h2 className="text-size-48">Something went wrong </h2>
            <h4>We apologize for the inconvenience. Please try again later.</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorShow;
