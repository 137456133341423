import React from 'react';
import { Tooltip } from 'react-tooltip';

import './index.css';
import { SelectOption } from '../../molecules/Select/Select';
import { capitalizeFirstLetter } from '../../../helpers/common';

export interface UsersListValueProps {
  users: SelectOption[];
  showStatus?: boolean;
}

const UsersListValue = ({ users, showStatus = true }: UsersListValueProps) => {
  return (
    <>
      {users && users?.length ? (
        <div
          className={`qtr-margin-top card user-value-card ${
            users.length === 1 ? 'overflow-visible' : ''
          }`}
        >
          <div>
            {users?.map((item: SelectOption, index) => (
              <div
                className="panel panel--bordered user-value-panel"
                key={`${item.value}${index}`}
              >
                <div>
                  <span
                    className={`${
                      item?.terminated?.toLowerCase() === 'y'
                        ? 'text-gray-400'
                        : null
                    }`}
                  >
                    {capitalizeFirstLetter(item?.firstName || '-') +
                      (' ' + capitalizeFirstLetter(item?.lastName || '-'))}{' '}
                    <span
                      className={`${
                        item?.terminated?.toLowerCase() === 'y'
                          ? 'text-gray-400'
                          : 'text-gray-600'
                      }`}
                    >
                      ({item?.email || '-'})
                    </span>
                  </span>

                  {item?.terminated?.toLowerCase() === 'y' &&
                  (item?.userRole === 'sponsor' || item?.userRole === 'pam') ? (
                    <>
                      <span
                        style={{ cursor: 'pointer' }}
                        data-tooltip-id={`tooltip-user-status-terminated-${index}-${item?.userId}`}
                        className="qtr-margin-left text-warning text-weight-700"
                      >
                        T
                      </span>
                      <Tooltip
                        id={`tooltip-user-status-terminated-${index}-${item?.userId}`}
                      >
                        <span style={{ zIndex: 1111 }}>
                          Terminated from Cisco
                        </span>
                      </Tooltip>
                    </>
                  ) : null}

                  {item?.terminated?.toLowerCase() === 'y' &&
                  item?.userRole === 'op' ? (
                    <>
                      <span
                        style={{ cursor: 'pointer' }}
                        data-tooltip-id={`tooltip-user-status-terminated-${index}-${item?.userId}`}
                        className="qtr-margin-left text-warning text-weight-700"
                      >
                        R
                      </span>
                      <Tooltip
                        id={`tooltip-user-status-terminated-${index}-${item?.userId}`}
                      >
                        <span style={{ zIndex: 1111 }}>
                          Removed from Operational Admin Group
                        </span>
                      </Tooltip>
                    </>
                  ) : null}

                  {showStatus &&
                  item?.activeFlag &&
                  item?.terminated?.toLowerCase() !== 'y' ? (
                    <span>
                      {item?.activeFlag?.toLowerCase() === 'y' ? (
                        <>
                          <span
                            className="icon-check-outline text-success qtr-margin-left"
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id={`tooltip-user-status`}
                          ></span>
                          <Tooltip id={`tooltip-user-status`}>
                            <span style={{ zIndex: 1111 }}>Active</span>
                          </Tooltip>
                        </>
                      ) : null}

                      {item?.activeFlag?.toLowerCase() === 'n' ? (
                        <>
                          {item?.status?.toLowerCase() === 'denied' ? (
                            <span
                              className="icon-blocked text-danger  qtr-margin-left"
                              style={{ cursor: 'pointer' }}
                              data-tooltip-id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                            ></span>
                          ) : (
                            <span
                              className="icon-time text-warning-alt  qtr-margin-left"
                              style={{ cursor: 'pointer' }}
                              data-tooltip-id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                            ></span>
                          )}

                          <Tooltip
                            id={`tooltip-pending-nomination-${item?.userId}-${index}`}
                          >
                            <div>
                              <div style={{ zIndex: 1111 }}>
                                Nomination is {item?.status?.toLowerCase()}
                              </div>
                              {item?.status?.toLowerCase() === 'denied' ? (
                                <div style={{ zIndex: 1111 }}>
                                  Remarks : {item?.remarks}
                                </div>
                              ) : null}
                            </div>
                          </Tooltip>
                        </>
                      ) : null}
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default UsersListValue;
