import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import FullScreenLoader from '../../molecules/FullScreenLoader';
import {
  fetchProfile,
  fetchUserType,
  fetchRolePermissions,
  getIsFederatedUser
} from '../../../actions';
import ErrorShow from '../../pages/ErrorShow';

const UserWrapper = () => {
  const dispatch = useDispatch();
  const userDetailsData = useSelector((state) => state.auth);
  const companyRegisterData = useSelector(
    (state) => state?.companyRegisterData
  );

  const {
    getUserTypeStatus,
    getUserTypeErrorMessage,
    getRolePermissionsStatus,
    getRolePermissionsErrorMessage,
    getIsFederatedUserStatus,
    mainUserType
  } = userDetailsData;

  const { getProfileStatus } = companyRegisterData;

  useEffect(() => {
    dispatch(fetchUserType());
    dispatch(fetchRolePermissions());
  }, []);

  useEffect(() => {
    if (mainUserType === 'EXTERNAL') {
      dispatch(fetchProfile({}, false));
      dispatch(getIsFederatedUser());
    }
  }, [mainUserType]);

  // if (
  //   getUserTypeStatus === 'SUCCESS' &&
  //   mainUserType === 'INTERNAL' &&
  //   userType &&
  //   !userType?.includes('pa') &&
  //   userType &&
  //   !userType?.includes('pam')
  // ) {
  //   return <OneAccessLink />;
  // } else {
  return (
    <>
      {(getUserTypeStatus === 'PENDING' ||
        getRolePermissionsStatus === 'PENDING' ||
        getIsFederatedUserStatus === 'PENDING') && (
        <FullScreenLoader title={'Fetching user details'} />
      )}
      {getUserTypeStatus === 'SUCCESS' &&
        getRolePermissionsStatus === 'SUCCESS' &&
        ((mainUserType === 'EXTERNAL' &&
          getProfileStatus === 'SUCCESS' &&
          getIsFederatedUserStatus === 'SUCCESS') ||
          mainUserType === 'INTERNAL') && <Outlet />}

      {/* {getUserTypeStatus === 'FAILURE' && (
        <div className="base-margin-top">
          <div className="alert alert--warning">
            <div className="alert__message">{getUserTypeErrorMessage}</div>
          </div>
        </div>
      )}
 */}
      {(getRolePermissionsStatus === 'FAILURE' ||
        getUserTypeStatus === 'FAILURE' ||
        getIsFederatedUserStatus === 'FAILURE' ||
        getProfileStatus === 'FAILURE') && <ErrorShow />}
    </>
  );
  //}
};

export default UserWrapper;
