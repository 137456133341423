import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import Loader from '../../atoms/Loader';
import { hasAccess, hasAccessForResourceRole } from '../../../helpers';
import { isItemInArrayIgnoreCase, noteToolTip } from '../../../helpers/common';

import Filters from '../../molecules/Filters';
import './index.css';
import { Tooltip } from 'react-tooltip';
import UserActionDialog from '../UserActionDialog';
import { fetchExternalUsersList } from '../../../actions/UserAction';
import ExcelExport from '../../atoms/ExcelExport';
import { fetchExternalUsers } from '../../../services/UsersService';

type AggridRefObj = React.RefObject<AgGridReact>;

// const noteToolTip = (id: string, text: string) => {
//   return (
//     <>
//       <span
//         className="icon-exclamation-circle text-warning-alt"
//         data-tooltip-id={`tooltip-${id}`}
//         data-tooltip-place="bottom"
//       ></span>
//       <Tooltip id={`tooltip-${id}`}>
//         <span style={{ zIndex: 1111 }}>{text}</span>
//       </Tooltip>
//     </>
//   );
// };

const sortDataByDescendingDate = (data: any, dateField: string) => {
  return data.slice().sort((a: any, b: any) => {
    const dateA = new Date(a[dateField]).getTime();
    const dateB = new Date(b[dateField]).getTime();
    return dateB - dateA;
  });
};

const AddressTooltip = (props: any) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex)?.data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>Address Line 1: {data?.address1 || '-'}</span>
      </p>
      <p>
        <span>Address Line 2: {data?.address2 || '-'}</span>
      </p>
      <p>
        <span>City: {data?.city || '-'}</span>
      </p>
      <p>
        <span>State: {data?.state || '-'}</span>
      </p>
      <p>
        <span>Country: {data?.country || '-'}</span>
      </p>
      <p>
        <span>Postal Code: {data?.zip || '-'}</span>
      </p>
    </div>
  );
};

const SearchUsers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState: any = location.state;
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType, user, userActiveRolePermissions } = authData;
  const gridRef: AggridRefObj = useRef(null);
  const [gridApi, setGridApi] = useState<any>(null);

  const [rows, setRows] = React.useState<any>([]);
  const [localFilter, setLocalFilter] = React.useState<any>(null);
  const [columns, setColumns] = React.useState<any>([]);
  const [row, setRow] = useState<any>();
  const [viewLoader, setViewLoader] = useState<any>(false);
  const [action, setAction] = useState<any>('');
  const [actionDialogOpen, setActionDialogOpen] = useState<boolean>(false);
  const [excelExportLoading, setExcelExportLoading] = useState<boolean>(false);

  const [status, setStatus] = useState<any>(undefined);
  const [filterValue, setFilterValue] = useState<any>({
    searchUserType: 'cco',
    includeInactiveUsers: false
  });

  const [submittedValue, setSubmittedValue] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [getDataErrorMessage, setGetDataErrorMessage] = useState(undefined);
  const [validation, setValidation] = useState<any>({});

  const [pageSize, setPageSize] = React.useState(10);

  const userData = useSelector((state: any) => state?.user?.externalUsersList);
  const getExternalUsersListStatus = useSelector(
    (state: any) => state?.user?.getExternalUsersListStatus
  );
  const { data: externalUsersList, filter } = userData || {};

  useEffect(() => {
    setFilterValue((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{
          filters: [
            {
              name: 'searchUserId',
              label: 'User Id',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'userName',
              label: 'User Name',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'searchEmail',
              label: 'Email',
              type: 'text',
              show: true,
              value: ''
            },
            {
              name: 'vendorName',
              label: 'Company Name',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'vendorId',
              label: 'Vendor Id',
              tooltip: noteToolTip(
                'vendorId',
                'Please enter complete Vendor ID'
              ),
              type: 'text',
              show: false,
              value: ''
            },

            {
              name: 'siteId',
              label: 'Site Id',
              type: 'text',
              show: false,
              value: ''
            },

            {
              name: 'primaryOrg',
              label: 'Primary Org',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'secOrg',
              label: 'Secondary Org',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'manager',
              label: 'Cisco Manager',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'status',
              label: 'SCREG Status',
              type: 'select',
              options: [
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' }
              ],
              show: false,
              value: ''
            }
          ]
            .filter((item) => {
              if (
                item.name === 'status' &&
                !hasAccess('users', 'statusFilter')
              ) {
                return false;
              }
              return true;
            })
            .filter((item) => {
              if (
                filterValue?.searchUserType === 'cec' &&
                ['manager', 'primaryOrg', 'secOrg', 'status'].includes(
                  item.name
                )
              ) {
                return false;
              }
              return true;
            })
        }
      };
    });
  }, [filterValue?.searchUserType]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    //  'notContains', 'equals', 'notEqual'
    const filterParams = {
      suppressAndOrCondition: true,
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual']
    };

    const columnsList = [
      {
        headerName: 'User Id',
        field: 'userId',
        filter: true,
        filterParams,
        tooltipField: 'userId',
        pinned: 'left',
        width: 180
      },
      {
        headerName: 'User Name',
        field: 'firstname',
        filter: true,
        filterParams,
        headerTooltip: 'User Name',
        tooltipField: 'firstname',
        minWidth: 120,
        flex: 1,
        cellRenderer: (params: any) => {
          return params?.value
            ? params?.value + ' ' + params?.data?.lastname
            : '';
        }
      },
      {
        headerName: 'Email',
        field: 'email',
        filter: true,
        filterParams,
        headerTooltip: 'Email',
        tooltipField: 'email',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Company Name',
        field: 'mcocompany',
        filter: true,
        filterParams,
        headerTooltip: 'Company Name',
        tooltipField: 'mcocompany',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Vendor Id',
        field: 'vendorId',
        filter: true,
        filterParams,
        headerTooltip: 'Vendor Id',
        tooltipField: 'vendorId',
        width: 100
      },
      {
        headerName: 'Site Id',
        field: 'supplierSiteId',
        filter: true,
        tooltipField: 'supplierSiteId',
        width: 100
      },
      {
        headerName: 'Site Address',
        field: 'fullAddress',
        filter: true,
        tooltipField: 'fullAddress',
        minWidth: 180,
        flex: 1,
        tooltipComponent: AddressTooltip
      },
      {
        headerName: 'Primary Organization',
        field: 'primaryOrg',
        filter: true,
        filterParams,
        headerTooltip: 'Primary Organization',
        tooltipField: 'primaryOrg',
        minWidth: 140,
        flex: 1
      },

      {
        headerName: 'Secondary Organization',
        field: 'secOrg',
        filter: true,
        filterParams,
        headerTooltip: 'Secondary Organization',
        tooltipField: 'secOrg',
        minWidth: 140,
        flex: 1,
        cellRenderer: (params: any) => {
          return params?.value?.split(',')?.join(', ') || '-';
        }
      },
      {
        headerName: 'Cisco Manager',
        field: 'manager',
        filter: true,
        filterParams,
        headerTooltip: 'Cisco Manager',
        tooltipField: 'manager',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Last login',
        field: 'oktaLastLogIn',
        headerTooltip: 'Last login',
        cellRenderer: (params: any) => {
          return params?.value
            ? moment(new Date(params?.value)).format('MM-DD-YYYY')
            : '-';
        },
        width: 110
      },
      {
        headerName: 'SCREG Status',
        field: 'profileStatus',
        filter: true,
        filterParams,
        headerTooltip: 'SCREG Status',
        tooltipField: 'profileStatus',
        width: 130,
        pinned: 'right',
        cellRenderer: (params: any) => {
          const fieldValue = params?.value?.toLowerCase();
          if (filterValue?.searchUserType === 'cec') {
            return (
              <span className="label label--success label--small label--bordered">
                Active
              </span>
            );
          }
          if (!fieldValue) return '';

          return (
            <>
              {fieldValue === 'active' ? (
                <span className="label label--success label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {fieldValue === 'inactive' ? (
                <span className="label label--warning label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {fieldValue !== 'active' && fieldValue !== 'inactive' ? (
                <span className="label label--light label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {params.data?.revalidationStatus?.toLowerCase() ===
              'inprocess' ? (
                <span className="qtr-margin-left">
                  Re-Validation:{' '}
                  <span className="">
                    {params.data?.revalidationStatus &&
                    params.data?.revalidationStatus?.toLowerCase() ===
                      'inprocess'
                      ? 'In Process'
                      : null}
                  </span>
                </span>
              ) : null}

              {params?.data?.profileStatus?.toLowerCase() === 'terminated' &&
              params.data?.rehire === true ? (
                <span className="qtr-margin-left">
                  Re-Onboard: <span className="">Enabled</span>
                </span>
              ) : null}
            </>
          );
        }
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 220,
        pinned: 'right',
        suppressHeaderMenuButton: true,
        cellRenderer: (params: any) => {
          const fieldValue = params?.data.value?.toLowerCase();
          return params.data ? (
            <>
              <Link to={`/cco-users-manage/details/${params.data?.userId}`}>
                View
              </Link>

              {params.data?.userId !== user?.ccoid &&
              params?.data?.ciscoitusersubtype?.toUpperCase() === 'EX01' &&
              params?.data?.profileStatus?.toLowerCase() === 'active' &&
              hasAccessForResourceRole(
                [
                  {
                    roleName: params?.data?.companyTypeGroupName || '',
                    exist: !!userActiveRolePermissions?.find(
                      (roleItem: any) =>
                        roleItem?.name === params?.data?.companyTypeGroupName
                    )
                  },
                  {
                    roleName: 'pam',
                    exist: !!params?.data?.PAM?.includes(user?.ccoid)
                  },
                  {
                    roleName: 'pa',
                    exist: isItemInArrayIgnoreCase(
                      user?.email || '',
                      params?.data?.PA && params?.data?.PA?.length
                        ? params?.data?.PA
                        : []
                    )
                  }
                ],
                'users',
                'deactivate'
              ) ? (
                <>
                  {' '}
                  |{' '}
                  <a
                    onClick={() => {
                      onClickActionDialogOpen(params.data, 'DEACTIVATE');
                    }}
                  >
                    Deactivate
                  </a>
                </>
              ) : null}

              {params.data?.userId !== user?.ccoid &&
              !params?.data?.rehire &&
              params?.data?.ciscoitusersubtype?.toUpperCase() === 'EX01' &&
              params?.data?.profileStatus?.toLowerCase() === 'terminated' &&
              hasAccessForResourceRole(
                [
                  {
                    roleName: params?.data?.companyTypeGroupName || '',
                    exist: !!userActiveRolePermissions?.find(
                      (roleItem: any) =>
                        roleItem?.name === params?.data?.companyTypeGroupName
                    )
                  },
                  {
                    roleName: 'pam',
                    exist: !!params?.data?.PAM?.includes(user?.ccoid)
                  },
                  {
                    roleName: 'pa',
                    exist: isItemInArrayIgnoreCase(
                      user?.email || '',
                      params?.data?.PA && params?.data?.PA?.length
                        ? params?.data?.PA
                        : []
                    )
                  }
                ],
                'users',
                'rehire'
              ) ? (
                <>
                  {' '}
                  |{' '}
                  <a
                    onClick={() => {
                      onClickActionDialogOpen(params.data, 'REHIRE');
                    }}
                  >
                    Re-Onboard
                  </a>
                </>
              ) : null}

              {params.data?.profileStatus?.toLowerCase() === 'active' &&
              !params.data?.isPA &&
              params.data?.revalidationStatus?.toLowerCase() !== 'inprocess' &&
              params.data?.userId !== user?.ccoid &&
              params?.data?.ciscoitusersubtype?.toUpperCase() === 'EX01' &&
              hasAccessForResourceRole(
                [
                  {
                    roleName: params?.data?.companyTypeGroupName || '',
                    exist: !!userActiveRolePermissions?.find(
                      (roleItem: any) =>
                        roleItem?.name === params?.data?.companyTypeGroupName
                    )
                  },
                  {
                    roleName: 'pam',
                    exist: !!params?.data?.PAM?.includes(user?.ccoid)
                  },
                  {
                    roleName: 'pa',
                    exist: isItemInArrayIgnoreCase(
                      user?.email || '',
                      params?.data?.PA && params?.data?.PA?.length
                        ? params?.data?.PA
                        : []
                    )
                  }
                ],
                'users',
                'initiateRevalidation'
              ) ? (
                <>
                  {' '}
                  |{' '}
                  <a
                    onClick={() => {
                      onClickActionDialogOpen(params.data, 'REVALIDATE');
                    }}
                  >
                    Revalidate
                  </a>
                </>
              ) : null}
            </>
          ) : null;
        }
      }
    ]
      .map((item: any) => {
        let itemTemp: any = item;
        itemTemp.resizable = true;
        // itemTemp.floatingFilter = true;

        itemTemp.columnChooserParams = {
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
          suppressColumnFilter: false
        };

        itemTemp.menuTabs = ['filterMenuTab'];

        if (item.field !== 'action') {
          itemTemp.sortable = true;

          // itemTemp.flex = 1;
        } else {
          itemTemp.sortable = false;
        }
        itemTemp.suppressHeaderMenuButton = true;
        // itemTemp.sortable = false;
        // itemTemp.filter = false;
        return itemTemp;
      })
      .filter(({ field }) => {
        if (
          submittedValue?.searchUserType === 'cec' &&
          ['primaryOrg', 'secOrg', 'manager'].includes(field)
        ) {
          return false;
        }

        return true;
      });
    setColumns(columnsList);
  }, [gridApi, submittedValue?.searchUserType]);

  useEffect(() => {
    if (gridApi) {
      if (viewLoader) gridApi.showLoadingOverlay();
      else gridApi.hideOverlay();
    }
  }, [viewLoader]);

  const onClickActionDialogOpen = (requestRow: any, action = '') => {
    setRow(requestRow);
    setActionDialogOpen(true);
    setAction(action);
  };

  const onUserUpdate = (responseRow: any) => {
    fetchUsers();
  };

  useEffect(() => {
    if (locationState?.fromSource !== 'externalUserDetails') {
      dispatch({ type: 'GET_EXTERNAL_USERS_LIST_RESET' });
    }
  }, [locationState?.fromSource]);

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const getOverlayNoRowsTemplate = () => {
    return 'No users found';
  };

  const handleChange = (event: any) => {
    if (event.target.name === 'includeInactiveUsers') {
      setFilterValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.checked }
        };
      });
    } else {
      setFilterValue((preVal: any) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.value }
        };
      });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (getExternalUsersListStatus === 'PENDING') {
      return;
    }
    if (!checkValidation()) {
      return;
    }

    setSubmittedValue(filterValue);

    fetchUsers();
  };

  const fetchUsers = () => {
    const userFilter: any = {
      userType: actualUserType
        ? actualUserType?.map((item: string) => item?.toUpperCase())
        : [],
      includeInactiveUsers: filterValue?.includeInactiveUsers,
      searchUserType: filterValue?.searchUserType
    };

    filterValue?.filters?.forEach((item: any) => {
      if (typeof item?.value !== 'undefined' && item?.show) {
        if (item?.type === 'select') {
          userFilter[item.name] = item?.value?.value;
        } else if (item?.value !== '') {
          userFilter[item.name] = item?.value;
        }
      }
    });

    dispatch(fetchExternalUsersList(userFilter, filterValue?.filters));
  };

  useEffect(() => {
    if (getExternalUsersListStatus === 'SUCCESS') {
      setIsSubmitted(true);
    }
    if (typeof getExternalUsersListStatus === 'undefined') {
      setIsSubmitted(false);
    }
  }, [getExternalUsersListStatus, filter]);

  useEffect(() => {
    if (filter && locationState?.fromSource === 'externalUserDetails') {
      setFilterValue((preVal: any) => {
        return { ...(preVal || {}), ...filter, filters: filter?.filters || [] };
      });
    }
  }, [filter, locationState?.fromSource]);

  useEffect(() => {
    if (gridApi) {
      if (
        getExternalUsersListStatus === 'SUCCESS' &&
        rows &&
        rows.length === 0
      ) {
        gridApi.showNoRowsOverlay();
      } else if (getExternalUsersListStatus !== 'PENDING') {
        gridApi.hideOverlay();
      }
    }
  }, [rows, getExternalUsersListStatus, gridApi]);

  useEffect(() => {
    if (localFilter) {
      let data = [];
      const filterModelKeys = Object.keys(localFilter.filterModel);
      setStatus('PENDING');
      data = rows.filter((row: any) => {
        const newRow = { ...row };
        newRow['firstname'] =
          row['firstname']?.trim() + ' ' + row['lastname']?.trim();
        let isVisile = false;
        for (const key of filterModelKeys) {
          isVisile = newRow[key]
            ?.trim()
            .includes(localFilter.filterModel[key].filter);
        }
        return isVisile;
      });
      setRows(data);
      localFilter.successCallback(data, data.length);
      setStatus('SUCCESS');
      setLocalFilter(null);
    }
  }, [localFilter?.filterModel]);

  useEffect(() => {
    let externalUsersListData = externalUsersList || [];
    externalUsersListData =
      sortDataByDescendingDate(externalUsersListData || [], 'updatedDate') ||
      [];
    externalUsersListData = externalUsersListData.map((item: any) => {
      item.fullAddress = `${item?.address1 || ''}${
        item?.address2 ? ', ' + item?.address2 + '' : ''
      }${item?.city ? ', ' + item?.city + '' : ''}${
        item?.state ? ', ' + item?.state + '' : ''
      }${item?.country ? ', ' + item?.country + '' : ''}${
        item?.zip ? ', ' + item?.zip + '' : ''
      }`;
      return item;
    });

    setRows(externalUsersListData);
  }, [externalUsersList]);

  useEffect(() => {
    checkValidation();
  }, [filterValue]);

  const checkValidation = () => {
    let errors: any = [];

    if (filterValue?.filters?.filter((item: any) => item?.show)?.length > 3) {
      errors.push({
        name: 'filterCount',
        message: 'Not allowed to add more than 3 filters'
      });
    }

    filterValue?.filters?.forEach((item: any) => {
      let error: any = {};
      if (item?.show) {
        let fieldValue;
        if (item?.type === 'select') {
          fieldValue = item?.value?.value?.trim();
        } else {
          fieldValue = item?.value?.trim();
        }
        if (fieldValue === '' || typeof fieldValue === 'undefined') {
          error.name = item?.name;
          error.message = `${item?.label} is required`;
        } else if (item?.name === 'vendorId') {
          if (isNaN(fieldValue)) {
            error.name = item?.name;
            error.message = `${item?.label} should be numeric`;
          }
        }
      }
      if (Object.keys(error).length) {
        errors.push(error);
      }
    });

    setValidation((preVal: any) => {
      return {
        ...(preVal || {}),
        ...{ filters: errors }
      };
    });

    return errors.length === 0;
  };

  const onChangeFilter = (filtersValue: any) => {
    const filtersValueTemp = filtersValue || [];
    let statusFilterHasValue = !!filtersValueTemp?.find((filterItem: any) => {
      return filterItem.name === 'status' && filterItem.value;
    });

    let statusFilterShow = !!filtersValueTemp?.find((filterItem: any) => {
      return filterItem.name === 'status' && filterItem.show;
    });

    //  if (statusFilterShow) {
    // filtersValueTemp.map((filterItem) => {
    //   if (filterItem.name !== 'status') {
    //     filterItem.disabled = true;
    //     filterItem.show = false;
    //   }
    //   return filterItem;
    // });
    //  } else {
    filtersValueTemp.map((filterItem: any) => {
      filterItem.disabled = false;
      return filterItem;
    });
    //}

    setFilterValue((preVal: any) => {
      return {
        ...({
          ...preVal,
          ...{
            includeInactiveUsers: statusFilterShow
              ? false
              : preVal?.includeInactiveUsers
          }
        } || {}),
        filters: filtersValueTemp
      };
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: false,
      minWidth: 100,
      filter: false,
      resizable: true
    };
  }, []);

  async function exportToExcel(exportData: any = []) {
    try {
      setExcelExportLoading(true);

      const userFilter: any = {
        userType: actualUserType
          ? actualUserType?.map((item: string) => item?.toUpperCase())
          : [],
        includeInactiveUsers: filterValue?.includeInactiveUsers,
        searchUserType: filterValue?.searchUserType
      };

      filterValue?.filters?.forEach((item: any) => {
        if (typeof item?.value !== 'undefined' && item?.show) {
          if (item?.type === 'select') {
            userFilter[item.name] = item?.value?.value;
          } else if (item?.value !== '') {
            userFilter[item.name] = item?.value;
          }
        }
      });

      const res = await fetchExternalUsers({
        ...userFilter,
        excelExport: true
      });
      setExcelExportLoading(false);
      if (res?.data) {
        return res?.data.map(
          ({
            userId,
            firstname,
            lastname,
            email,
            mcocompany,
            vendorId,
            primaryOrg,
            secOrg,
            profileStatus,
            manager,
            country,
            supplierSiteId,
            address1,
            address2,
            city,
            state,
            zip,
            oktaLastLogIn
          }: any) => {
            const siteAddress = `${address1 ? address1 : ''}${
              address2 ? ', ' + address2 : ''
            }${city ? ', ' + city : ''}${state ? ', ' + state : ''}${
              country ? ', ' + country : ''
            }${zip ? ', ' + zip : ''}`;
            return {
              'User Id': userId,
              Email: email,
              'User Name': firstname + (lastname ? ' ' + lastname : ''),
              'Company Name': mcocompany,
              'Vendor Id': vendorId,
              'Site Id': supplierSiteId == '0' ? '' : supplierSiteId,
              'Site Address': siteAddress || '',
              'Primary Organization': primaryOrg,
              'Secondary Organization': secOrg,
              'Cisco Manager': manager,
              'Last login': oktaLastLogIn
                ? moment(new Date(oktaLastLogIn)).format('MM-DD-YYYY')
                : '',
              'SCREG Status': profileStatus
            };
          }
        );
      }
      return [{ error: 'No Data' }];
    } catch (err: any) {
      setExcelExportLoading(false);
      return [{ error: err?.message }];
    }
  }

  return (
    <div className="">
      <div className="card qtr-margin-bottom">
        <div className="card-header half-padding">
          <div className="flex">
            <h5 className="display-5">Manage CCO Users</h5>
          </div>
        </div>

        <div className="card-body">
          <form method="POST" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="filter-bar-row filter-tool">
                  <div className="filter-bar-item filter-bar-left filter-tool-item">
                    {filterValue?.searchUserType === 'cco' ? (
                      <div className="">
                        <div
                          className="form-group form-group--inline form-group--compressed half-margin-top"
                          style={
                            filterValue?.filters?.find(
                              (filterItem: any) => filterItem?.name === 'status'
                            )?.show
                              ? { backgroundColor: '#eee' }
                              : {}
                          }
                        >
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="includeInactiveUsers"
                              checked={
                                filterValue?.includeInactiveUsers === true
                              }
                              onChange={handleChange}
                              disabled={
                                filterValue?.filters?.find(
                                  (filterItem: any) =>
                                    filterItem?.name === 'status'
                                )?.value
                              }
                            />
                            <span className="checkbox__input"></span>
                            <span className="checkbox__label">
                              Include inactive users in search{' '}
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="filter-bar-item filter-bar-center filter-tool-item">
                    <div className="row">
                      <div className="col-auto" style={{ maxWidth: '100%' }}>
                        <Filters
                          filters={filterValue?.filters || []}
                          onChange={onChangeFilter}
                          validation={
                            isSubmitted && validation?.filters
                              ? validation?.filters
                              : []
                          }
                          searchButton={
                            <>
                              <button
                                type="submit"
                                className="btn"
                                disabled={
                                  getExternalUsersListStatus === 'PENDING'
                                }
                              >
                                Search
                              </button>
                              <div className="">
                                <span className="text-warning text-size-12">
                                  Note : Please select up to 3 filters{' '}
                                </span>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="filter-bar-item filter-bar-right filter-tool-item">
                    <div className="filter-bar-right-content">
                      {hasAccess('users', 'excelExport') && (
                        <div className="pull-right">
                          <ExcelExport
                            disabled={
                              excelExportLoading ||
                              getExternalUsersListStatus === 'PENDING' ||
                              rows.length == 0
                            }
                            loading={excelExportLoading}
                            fileName={`CCO_users`}
                            getData={() => exportToExcel(rows)}
                            hidden={rows.length == 0}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {validation?.filters &&
                validation?.filters?.filter(
                  (filterItem: any) => filterItem?.name === 'filterCount'
                )?.length ? (
                  <div
                    className="alert alert--warning"
                    style={{ marginBottom: '8px' }}
                  >
                    <div className="alert__icon icon-warning-outline"></div>
                    <div className="alert__message">
                      {
                        validation?.filters?.find(
                          (filterItem: any) =>
                            filterItem?.name === 'filterCount'
                        )?.message
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>

      {!isSubmitted ? (
        <div>
          <div className="text-center">
            <span className="icon-file-text text-size-128 text-gray-300"></span>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {isSubmitted &&
      getExternalUsersListStatus === 'SUCCESS' &&
      rows.length === 0 ? (
        <div>
          <div className="text-center">
            <span className="icon-file text-size-128 text-gray-300"></span>
            <div className="text-secondary text-size-20 base-margin-bottom">
              No Results
            </div>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {getExternalUsersListStatus === 'PENDING' ? (
        <div className="dbl-padding " style={{ marginTop: '10%' }}>
          <div className="text-center">
            <Loader />
          </div>
        </div>
      ) : null}

      <div
        className={`ag-theme-alpine`}
        style={{
          height: 485,
          width: '100%',
          display:
            isSubmitted &&
            getExternalUsersListStatus === 'SUCCESS' &&
            rows.length
              ? 'block'
              : 'none'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={pageSize}
          onGridReady={onGridReady}
          gridOptions={{ suppressContextMenu: true }}
          overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
          loadingOverlayComponent={Loader}
          enableCellTextSelection={true}
          suppressCellFocus={true}
          getRowStyle={getRowStyle}
          headerHeight={38}
          rowHeight={38}
          animateRows={true}
          tooltipShowDelay={0}
          loading={getExternalUsersListStatus === 'PENDING'}
          paginationPageSizeSelector={false}
        ></AgGridReact>
      </div>

      <UserActionDialog
        details={row}
        open={actionDialogOpen}
        action={action || ''}
        onChange={(dialogStatus: boolean) => {
          setActionDialogOpen(dialogStatus);
        }}
        onSuccess={onUserUpdate}
      />
    </div>
  );
};
export default SearchUsers;
