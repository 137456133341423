import React from 'react';
import { config } from '../../../config/config';

const UserProfile = ({ profile }) => {
  const { oneAccessUrl } = config;
  return (
    <div className="">
      {profile && (
        <>
          {profile?.profileStatus?.toLowerCase() === 'active' ? (
            <div className="base-margin-bottom base-margin-top">
              <h4 className="text-weight-600 ">
                You are an authorized Supply Chain user.{' '}
                {profile.mcocompany &&
                  'Please find your profile information below.'}
              </h4>
            </div>
          ) : null}

          {profile?.profileStatus?.toLowerCase() === 'inactive' ||
          profile?.profileStatus?.toLowerCase() === 'terminated' ? (
            <div className="base-margin-bottom base-margin-top">
              <div className="alert alert--warning">
                <div className="alert__icon icon-warning-outline"></div>
                <div className="alert__message">
                  Your profile is <b>{profile?.profileStatus?.toLowerCase()}</b>
                  .
                  <div className="qtr-margin-top">
                    Please contact{' '}
                    <a href="mailto:sciam-support@cisco.com">
                      sciam-support@cisco.com
                    </a>{' '}
                    to activate your profile.
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {profile?.profileStatus?.toLowerCase() === 'active' &&
          profile.mcocompany ? (
            <div className="panel panel--bordered qtr-margin-top">
              <div className="profile-wrap base-margin-top">
                <div className="row">
                  <div className="col">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <label className="qtr-margin-bottom">
                          <b>Company Type</b>
                        </label>
                        <span>{profile.mcompanytype}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <label className="qtr-margin-bottom">
                          <b>Company Name</b>
                        </label>
                        <span>{profile.mcocompany}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <label className="qtr-margin-bottom">
                          <b>Child Company</b>
                        </label>

                        <span>{profile.childCompanyName}</span>
                      </div>
                    </div>
                  </div> */}
                  <div className="col">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <label className="qtr-margin-bottom">
                          <b>Primary Organization</b>
                        </label>

                        <span>{profile.mcouserorg}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group base-margin-bottom">
                      <div className="form-group__text">
                        <label className="qtr-margin-bottom">
                          <b>Secondary Organization</b>
                        </label>

                        <span>
                          {profile.mcomultiorg?.split(',')?.join(', ') || '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
      {profile?.profileStatus?.toLowerCase() === 'active' ? (
        <div className="base-margin-top panel panel--loose panel--gray base-padding">
          <h5>Please click on below button to request Supply Chain roles.</h5>
          <a
            href={oneAccessUrl}
            target="_blank"
            className="btn btn--small"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
          >
            One Access
          </a>
        </div>
      ) : null}
    </div>
  );
};
export default UserProfile;
