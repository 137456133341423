import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export interface DeactivatePayload {
  deactiveUserId: string;
  userType?: string[];
  remarks?: string;
}

export interface ReactivatePayload {
  reactivatingUserId: string;
  reactivatingEmail: string;
  userType?: string[];
  remarks?: string;
}

export interface RehirePayload {
  reactivatingUserId: string;
  reactivatingEmail: string;
  userType?: string[];
  remarks?: string;
}

export interface PARemovePayload {
  paUserId: string;
  paUserEmail: string;
  paFirstName?: string;
  paLastName?: string;
  companyIdList: string[];
  userType?: string[];
  remarks?: string;
}

export const deactivateUser = async (payload: DeactivatePayload) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/user/profile/deactive/submit`,
      payload
    );
    return res;
  } catch (error: any) {
    let errorMessage = '';
    if (error?.response?.status != 401) {
      errorMessage = `An error occurred while deactivating user ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};

export const reactivateUser = async (payload: ReactivatePayload) => {
  try {
    const res = await httpUtil.post(`/api/user/v1/user/reactivate`, payload);
    return res;
  } catch (error: any) {
    let errorMessage = '';
    if (error?.response?.status != 401) {
      errorMessage = `An error occurred while reactivating user ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};

export const rehireUser = async (payload: RehirePayload) => {
  try {
    const res = await httpUtil.post(`/api/user/v1/user/rehire`, payload);
    return res;
  } catch (error: any) {
    let errorMessage = '';
    if (error?.response?.status != 401) {
      errorMessage = `An error occurred while enabling  user Re-Onboard  ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};

export const fetchExternalUserDetails = async (payload: any = {}) => {
  try {
    const res = await httpUtil.post<unknown, any>(
      `/api/am/dashboard/v1/external/deactivate/usersView`,
      payload
    );
    return res;
  } catch (error: any) {
    if (error?.response?.status != 401) {
      const errorMessage = `An error occurred while fetching external user details ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};

export const fetchUserAccountReviewDetails = async (body: object = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/user/revalidation/v1/user-account-revalidation/details`,
      body
    );
    return res && res?.length ? res : [];
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching user review details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const removePAUser = async (payload: PARemovePayload) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/user/profile/remove/pa`,
      payload
    );
    return res;
  } catch (error: any) {
    let errorMessage = '';
    if (error?.response?.status != 401) {
      errorMessage = `An error occurred while removing user as Partner Administrator ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};

export const fetchExternalUsers = async (payload: any = {}) => {
  try {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/external/deactivate/usersSearch`,
      payload
    );
    return res;
  } catch (error: any) {
    let errorMessage = '';
    if (error?.response?.status != 401) {
      errorMessage = `An error occurred while fetching external users ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
    return Promise.reject(error);
  }
};
