import { Action } from '../../actions/ActionTypes.interface';

const initialState: any = {};

export const companyReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case 'GET_COMPANIES_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getCompanyListStatus: 'SUCCESS',
          companiesData: action.payload,
          getCompanyListErrorMessage: null
        }
      };
    }
    case 'GET_COMPANIES_LIST_START': {
      return {
        ...state,
        ...{ getCompanyListStatus: 'PENDING', getCompanyListErrorMessage: null }
      };
    }
    case 'GET_COMPANIES_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getCompanyListStatus: 'FAILURE',
          getCompanyListErrorMessage: action.errorMessage
        }
      };
    }

    case 'COMPANY_LIST_RESET': {
      return {
        ...state,
        ...{
          getCompanyListStatus: undefined,
          companiesData: undefined,
          getCompanyListErrorMessage: null,
          filter: undefined
        }
      };
    }

    case 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_COMPLETE': {
      return {
        ...state,
        ...{
          getCompanyAccountApprovalListStatus: 'SUCCESS',
          companyAccountApprovals: action.payload,
          getCompanyAccountApprovalListErrorMessage: null
        }
      };
    }
    case 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_START': {
      return {
        ...state,
        ...{
          getCompanyAccountApprovalListStatus: 'PENDING',
          getCompanyListErrorMessage: null
        }
      };
    }
    case 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_FAILURE': {
      return {
        ...state,
        ...{
          getCompanyAccountApprovalListStatus: 'FAILURE',
          getCompanyAccountApprovalListErrorMessage: action.errorMessage
        }
      };
    }

    case 'COMPANIES_ACCOUNT_APPROVAL_LIST_RESET': {
      return {
        ...state,
        ...{
          getCompanyAccountApprovalListStatus: undefined,
          companyAccountApprovals: undefined,
          getCompanyAccountApprovalListErrorMessage: null,
          filter: undefined
        }
      };
    }

    case 'GET_COMPANY_REVALIDATION_LIST_COMPLETE': {
      const { tableType } = action;
      return {
        ...state,
        getCompanyRevalidationListStatus: {
          ...state.getCompanyRevalidationListStatus,
          [tableType]: 'SUCCESS'
        },
        companyRevalidationList: {
          ...state.companyRevalidationList,
          [tableType]: action.payload
        },
        getCompanyRevalidationListErrorMessage: null
      };
    }
    case 'GET_COMPANY_REVALIDATION_LIST_START': {
      const { tableType } = action;
      return {
        ...state,
        ...{
          getCompanyRevalidationListStatus: {
            ...state.getCompanyRevalidationListStatus,
            [tableType]: 'PENDING'
          },
          getCompanyRevalidationListErrorMessage: null
        }
      };
    }
    case 'GET_COMPANY_REVALIDATION_LIST_FAILURE': {
      const { tableType } = action;
      return {
        ...state,
        ...{
          getCompanyRevalidationListStatus: {
            ...state.getCompanyRevalidationListStatus,
            [tableType]: 'FAILURE'
          },
          getCompanyRevalidationListErrorMessage: action.errorMessage
        }
      };
    }

    case 'COMPANY_REVALIDATION_LIST_RESET': {
      return {
        ...state,
        ...{
          getCompanyRevalidationListStatus: undefined,
          companyRevalidationList: undefined,
          getCompanyRevalidationListErrorMessage: null
        }
      };
    }

    default:
      return state;
  }
};
