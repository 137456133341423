import axios from 'axios';

import { config as mainConfig } from '../config/config';
const { apiUrl } = mainConfig;

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

export { store };

export const authInstance = axios.create({
  baseURL: apiUrl
});

authInstance.interceptors.request.use(
  function (config: any) {
    config.headers['Content-Type'] = 'application/json';
    let user = store.getState()?.auth?.user;
    let token = user?.accessToken;
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }
    if (
      user &&
      (config.method === 'post' ||
        config.method === 'put' ||
        config.method === 'delete')
    ) {
      config.data = {
        ...(config.data || {}),
        ...{ firstName: user?.first_name || '', lastName: user?.last_name }
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
authInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    console.log('error', error);
    /* message "Request aborted" should not trow error */
    if (error.message === 'Request aborted') {
      return new Promise(() => {});
    }

    if (error.response.status === 401) {
      const fromUri = window.location.href;
      let urlQuery = '';
      if (fromUri) {
        urlQuery = '?redirectUrl=' + encodeURIComponent(fromUri);
      }
      window.location.href = '/login' + urlQuery;
      return new Promise(() => {});
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default authInstance;
