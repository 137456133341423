import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, matchPath, Navigate } from 'react-router-dom';

import { RouteWithResourceProps } from '../../../App';

import { hasRouteAccess } from '../../../helpers';
import { Permission } from '../../../interfaces/permission';

export interface SecureWrapperProps {
  routes: RouteWithResourceProps[];
}

const SecureWrapper = ({ routes }: SecureWrapperProps) => {
  const { pathname } = useLocation();
  const userDetailsData: any = useSelector<any>((state) => state.auth);

  const { user, mainUserType, userType, isFederatedUser } = userDetailsData;

  const [isAccessible, setIsAccessible] = useState<boolean | undefined>();

  const appUserHasAccess = (routePermissions: Permission[]) => {
    for (let i = 0; i < routePermissions?.length; i++) {
      for (let j = 0; j < routePermissions[i]?.actions?.length; j++) {
        const resource = routePermissions[i]?.resource;
        const action = routePermissions[i]?.actions[j];
        // Only external users can view profile
        if (resource === 'myProfile') {
          if (mainUserType === 'EXTERNAL') {
            return true;
          } else {
            return false;
          }
        }

        if (resource === 'requests') {
          if (
            isFederatedUser &&
            !(userType?.includes('pa') || userType?.includes('pam'))
          ) {
            return false;
          } else if (
            !userType?.includes('pa') &&
            !userType?.includes('pam') &&
            action === 'readHistory'
          ) {
            return false;
          }

          return true;
        }

        // if (
        //   userType?.includes('pa') ||
        //   userType?.includes('pam') ||
        //   userType?.includes('sponsor')
        // ) {
        //   if (resource === 'company') {
        //     if (action === 'read' || !action) return true;
        //   } else if (resource === 'users') {
        //     if (action === 'read' || !action) return true;
        //   }
        // }
      }
    }
    return undefined;
  };

  useEffect(() => {
    /* Check active Secure Route : Defined in APP component */
    const activeSecureRoute = routes?.find((item) =>
      item.path ? matchPath(item.path, pathname) : false
    );
    /* Check user has permission to visit page */
    if (activeSecureRoute) {
      const routePermission = activeSecureRoute.permissions;
      // check is application based user has access
      const appUserHasAccessCheck = appUserHasAccess(routePermission);
      if (appUserHasAccessCheck !== undefined) {
        setIsAccessible(appUserHasAccessCheck);
      } else {
        // check is Role based user has access
        if (hasRouteAccess(routePermission)) {
          setIsAccessible(true);
        } else {
          setIsAccessible(false);
        }
      }
    } else {
      setIsAccessible(true);
    }
  }, [user, pathname, routes, mainUserType, userType]);

  if (isAccessible !== undefined && isAccessible === true) {
    return <Outlet />;
  } else if (isAccessible !== undefined && isAccessible === false) {
    return <Navigate to={'/unauthorized'} replace={true} />;
  }
  return <></>;
};

export default SecureWrapper;
