import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Outlet,
  RouteProps
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseManager } from 'ag-grid-enterprise';

import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { v4 as uuidv4 } from 'uuid';

import './App.css';
import MainLayout from './components/layouts/MainLayout';
import Home from './components/pages/Home';
import Header from './components/templates/Header';
import Footer from './components/templates/Footer';
import UserBody from './components/templates/UserBody';
import BannerMessage from './components/organisms/BannerMessage';
// import RegisterUserCompany from './components/pages/RegisterUserCompany';
import Store from './reducers';
import PrivateWrapper from './components/molecules/PrivateWrapper';
import UserWrapper from './components/molecules/UserWrapper';
import SecureWrapper from './components/molecules/SecureWrapper';
import UserRoleValidateWrapper from './components/molecules/UserRoleValidateWrapper';
import ProfileStatusCheckWrapper from './components/molecules/ProfileStatusCheckWrapper';

// import RequestStatus from './components/pages/RequestStatus';
import Profile from './components/pages/Profile';
import CompanyOnboard from './components/pages/CompanyOnboard';
import Requests from './components/pages/Requests';
import RequestHistory from './components/pages/RequestHistory';
// import Dashboard from './components/pages/Dashboard';
import UsersManage from './components/pages/UsersManage';
import ExternalUsersManage from './components/pages/ExternalUsersManage';
import ExternalUserDetails from './components/pages/ExternalUserDetails';
import TransparentFullScreenLoader from './components/molecules/FullScreenLoader/TransparentFullScreenLoader';

import Invitations from './components/pages/Invitations';

import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import NotFound from './components/pages/NotFound';
import Unauthorized from './components/pages/Unauthorized';
import CompanyManage from './components/pages/CompanyManage';
import CompanyEdit from './components/pages/CompanyEdit';
import AuditReportSchedule from './components/pages/AuditReportSchedule';
import AuditReportScheduleCreate from './components/pages/AuditReportScheduleCreate';
import AuditReportScheduleView from './components/pages/AuditReportScheduleView';
import AuditReportScheduleModify from './components/pages/AuditReportScheduleModify';
import UserProfileMandatoryUpdate from './components/molecules/UserProfileMandatoryUpdate';

import MailApproval from './components/pages/MailApproval';
import Feedback from './components/pages/Feedback';
const CompanyTypeManage = React.lazy(
  () => import('./components/pages/CompanyTypeManage')
);
const AuditReports = React.lazy(
  () => import('./components/pages/AuditReports')
);
const CompanyAccountApproval = React.lazy(
  () => import('./components/pages/CompanyAccountApproval')
);
const CompanyAccountApprovalDetails = React.lazy(
  () => import('./components/pages/CompanyAccountApprovalDetails')
);

const CompanyRevalidation = React.lazy(
  () => import('./components/pages/CompanyRevalidation')
);

const CompanyRevalidationDetails = React.lazy(
  () => import('./components/pages/CompanyRevalidationDetails')
);

const UserAccountReview = React.lazy(
  () => import('./components/pages/UserAccountReview')
);

const UserAccountReviewDetails = React.lazy(
  () => import('./components/pages/UserAccountReviewDetails')
);

const CompanyReports = React.lazy(
  () => import('./components/pages/CompanyReports')
);

const UserReports = React.lazy(() => import('./components/pages/UserReports'));

import { injectStore } from './helpers/interceptor';
import { config } from './config/config';

import { Permission } from './interfaces/permission';

injectStore(Store);

export type RouteWithResourceProps = {
  permissions: Permission[];
} & RouteProps;

const { okta, agGridLicenseKey } = config;
LicenseManager.setLicenseKey(agGridLicenseKey);

const oktaLoginCallbackUrl = '/login/callback';

const oktaAuth = new OktaAuth({
  issuer: `https://${okta.oktaDomain}/oauth2/default`,
  clientId: `${okta.oktaClientId}`,
  redirectUri: window.location.origin + oktaLoginCallbackUrl,
  scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
  pkce: true,
  tokenManager: {
    autoRenew: false, // Set to false if you want to handle renewal manually
    storage: 'localStorage'
  }
});

const Layout = () => {
  return (
    <MainLayout
      header={<Header />}
      footer={<Footer />}
      banner={
        <>
          <BannerMessage />
          <UserProfileMandatoryUpdate />
        </>
      }
    >
      <Outlet />
    </MainLayout>
  );
};

function App() {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    window.location.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const generateSessionId = () => {
    const sessionId = uuidv4();
    localStorage.setItem('sessionId', sessionId);
  };

  useEffect(() => {
    if (window.location.pathname === oktaLoginCallbackUrl) {
      generateSessionId();
    }
  }, []);

  const CloseButton = ({ closeToast }: any) => (
    <span className="icon-close" onClick={closeToast}></span>
  );

  const routes: RouteWithResourceProps[] = [
    {
      path: '/my-profile',
      permissions: [{ resource: 'myProfile', actions: ['read', 'edit'] }],
      element: <Profile />
    },
    {
      path: '/company-onboard',
      permissions: [{ resource: 'company', actions: ['create'] }],
      element: <CompanyOnboard />
    },
    {
      path: '/company-manage',
      permissions: [
        { resource: 'company', actions: ['read', 'update', 'delete'] }
      ],
      element: <CompanyManage />
    },
    {
      path: '/search-users',
      permissions: [
        {
          resource: 'users',
          actions: ['read', 'add', 'update', 'delete']
        }
      ],
      element: <UsersManage />
    },

    {
      path: '/cco-users-manage',
      permissions: [
        {
          resource: 'users',
          actions: ['deactivate']
        }
      ],
      element: <ExternalUsersManage />
    },
    {
      path: '/cco-users-manage/:action/:id',
      permissions: [
        {
          resource: 'users',
          actions: ['deactivate']
        }
      ],
      element: <ExternalUserDetails />
    },
    {
      path: '/active-request',
      permissions: [
        {
          resource: 'requests',
          actions: ['read', 'add', 'update', 'delete']
        }
      ],
      element: <Requests />
    },
    {
      path: '/request-history',
      permissions: [
        {
          resource: 'requests',
          actions: ['readHistory']
        }
      ],
      element: <RequestHistory />
    },
    {
      path: '/company-modify/:id',
      permissions: [
        {
          resource: 'company',
          actions: [
            'update',
            'updatePAM',
            'updatePA',
            'updateOP',
            'updateDomain',
            'updateSponsor',
            'updateOperationalAdmin'
          ]
        }
      ],
      element: <CompanyEdit />
    },
    {
      path: '/invitations',
      permissions: [
        {
          resource: 'invitations',
          actions: ['read']
        }
      ],
      element: <Invitations />
    },
    {
      path: '/company-type-manage',
      permissions: [
        {
          resource: 'companyType',
          actions: ['read', 'add', 'update', 'delete']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyTypeManage />
        </React.Suspense>
      )
    },
    {
      path: '/company-account-modification-audit-reports',
      permissions: [
        {
          resource: 'auditReports',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <AuditReports />
        </React.Suspense>
      )
    },
    {
      path: '/company-account-lifecycle-audit-reports',
      permissions: [
        {
          resource: 'auditReports',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyReports />
        </React.Suspense>
      )
    },
    {
      path: '/user-account-lifecycle-audit-reports',
      permissions: [
        {
          resource: 'auditReports',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <UserReports />
        </React.Suspense>
      )
    },
    {
      path: '/audit-report-schedule',
      permissions: [
        {
          resource: 'auditReportSchedule',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <AuditReportSchedule />
        </React.Suspense>
      )
    },
    {
      path: '/audit-report-schedule/create',
      permissions: [
        {
          resource: 'auditReportSchedule',
          actions: ['create']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <AuditReportScheduleCreate />
        </React.Suspense>
      )
    },
    {
      path: '/audit-report-schedule/update/:id',
      permissions: [
        {
          resource: 'auditReportSchedule',
          actions: ['view', 'update']
        }
      ],
      element: (
        <React.Suspense fallback={<>...</>}>
          <AuditReportScheduleModify />
        </React.Suspense>
      )
    },
    {
      path: '/audit-report-schedule/:action/:id',
      permissions: [
        {
          resource: 'auditReportSchedule',
          actions: ['view', 'update']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <AuditReportScheduleView />
        </React.Suspense>
      )
    },
    {
      path: '/company-account-approval',
      permissions: [
        {
          resource: 'companyAccountApproval',
          actions: ['read', 'update']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyAccountApproval />
        </React.Suspense>
      )
    },
    {
      path: '/company-account-approval/:action/:id',
      permissions: [
        {
          resource: 'companyAccountApproval',
          actions: ['read', 'update']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyAccountApprovalDetails />
        </React.Suspense>
      )
    },

    {
      path: '/company-account-revalidation',
      permissions: [
        {
          resource: 'companyAccountRevalidation',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyRevalidation />
        </React.Suspense>
      )
    },
    {
      path: '/company-account-revalidation/:action/:id',
      permissions: [
        {
          resource: 'companyAccountRevalidation',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <CompanyRevalidationDetails />
        </React.Suspense>
      )
    },
    {
      path: '/user-account-review',
      permissions: [
        {
          resource: 'userAccountReview',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <UserAccountReview />
        </React.Suspense>
      )
    },
    {
      path: '/user-account-review/:action/:id',
      permissions: [
        {
          resource: 'userAccountReview',
          actions: ['read']
        }
      ],
      element: (
        <React.Suspense fallback={<TransparentFullScreenLoader />}>
          <UserAccountReviewDetails />
        </React.Suspense>
      )
    }
  ];

  return (
    <div className="app">
      <ToastContainer closeButton={CloseButton} />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Router>
          <Routes>
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route element={<PrivateWrapper />}>
              {/* <Route path='/' exact={true} element={<NotFound/>}/> */}
              {/* <Route path="/" element={<Home />} /> */}
              {/* <Route path="/logout" element={<Logout />} /> */}

              {/* <Route element={<PrivateWrapper />}> */}
              <Route element={<UserWrapper />}>
                <Route element={<UserRoleValidateWrapper />}>
                  <Route element={<SecureWrapper routes={routes} />}>
                    <Route element={<Layout />}>
                      <Route element={<ProfileStatusCheckWrapper />}>
                        <Route
                          path="/"
                          element={
                            <UserBody>
                              <Home />
                            </UserBody>
                          }
                        />

                        {/* <Route
                        path="/request-history"
                        element={<RequestHistory />}
                      />
                      <Route path="/active-request" element={<Requests />} /> */}
                        {/* <Route path="/home" element={<Dashboard />} /> */}

                        {routes.map(({ path, element }, index) => {
                          return path ? (
                            <Route
                              key={`Route${index}`}
                              path={path}
                              element={element}
                            />
                          ) : (
                            ''
                          );
                        })}

                        {/* <Route path="/request" element={<RequestStatus />} /> */}
                        <Route path="/feedback" element={<Feedback />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            {/* </Route> */}

            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/mail-approval/:id" element={<MailApproval />} />
            <Route path="*" element={<NotFound />} />

            {/* <Route element={<Layout />}>
            <Route path="/login-error" element={<Home />} />
          </Route> */}
          </Routes>
        </Router>
      </Security>
    </div>
  );
}

const parentApp = () => {
  return (
    <Provider store={Store}>
      <App />
    </Provider>
  );
};
export default parentApp;
