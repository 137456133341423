import React from 'react';

export interface RefreshButtonProps {
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  arialLabel?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  btnClass?: string;
}

const RefreshButton = ({
  title,
  loading,
  disabled,
  arialLabel,
  onClick,
  btnClass = 'btn--small'
}: RefreshButtonProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`btn  btn--ghost ${btnClass}`}
      aria-label={arialLabel}
      disabled={loading || disabled}
    >
      {title || 'Refresh'}{' '}
      <span
        className={`icon-refresh ${loading ? 'spin' : ''}`}
        style={{ lineHeight: '24px' }}
      ></span>
    </button>
  );
};
export default RefreshButton;
