import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Loader from '../../atoms/Loader';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import {
  DeactivatePayload,
  deactivateUser,
  reactivateUser,
  rehireUser,
  ReactivatePayload,
  RehirePayload
} from '../../../services/UsersService';
import {
  initiateUserRevalidation,
  UserRevalidationPayload
} from '../../../services/UserRevalidationService';
import { isItemInArrayIgnoreCase } from '../../../helpers/common';

export interface UserActionDialogProps {
  buttonTitle?: string;
  details: any;
  action: string;
  onSuccess?: Function;
  open: boolean;
  onChange?: Function;
}

const UserActionDialog = ({
  buttonTitle,
  details,
  action: propAction,
  onSuccess,
  open,
  onChange
}: UserActionDialogProps) => {
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType } = authData;
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [remark, setRemark] = useState('');
  const [validation, setValidation] = useState<{ remark?: string }>({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [allowed, setAllowed] = useState<boolean | undefined>();
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    setConfirmDialogOpen(!!open);
  }, [open]);

  useEffect(() => {
    return () => {
      setRemark('');
    };
  }, [details]);

  useEffect(() => {
    setStatusAction(propAction);
    setRemark('');
    setSubmitted(false);
  }, [propAction]);

  useEffect(() => {
    checkValidation(statusAction === 'DEACTIVATE' || statusAction === 'REHIRE');
  }, [remark, statusAction]);

  const onRemarkChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(event.target.value);
  };
  const checkValidation = (isRemarkRequired = false) => {
    let errors: { remark?: string } = { ...validation };
    let remarkValue = remark?.trim();

    if (!remarkValue && isRemarkRequired) {
      errors.remark = 'Required';
    } else if (isRemarkRequired && remarkValue && remarkValue.length < 25) {
      errors.remark = 'Justification must be at least 25 characters';
    } else if (isRemarkRequired && remarkValue && remarkValue.length > 250) {
      errors.remark = "You can't write more than 250 characters";
    } else {
      delete errors.remark;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updatedUserAccount = async (
    action: string,
    userUpdateData: DeactivatePayload | UserRevalidationPayload | RehirePayload
  ) => {
    if (
      checkValidation(
        statusAction === 'DEACTIVATE' || statusAction === 'REHIRE'
      )
    ) {
      try {
        setIsUpdateLoading(true);

        let result: any = {};
        if (statusAction === 'REVALIDATE') {
          result = await initiateUserRevalidation(userUpdateData);
          setIsUpdateLoading(false);
          notify(
            `User revalidation has been initiated successfully`,
            'success'
          );
          if (onSuccess) {
            onSuccess(details);
          }
          setConfirmDialogOpen(false);
          if (onChange) onChange(false);
        } else if (statusAction === 'DEACTIVATE') {
          result = await deactivateUser(userUpdateData as DeactivatePayload);
          setIsUpdateLoading(false);
          const responseStatus = result?.status?.toLowerCase();
          if (responseStatus === 'success') {
            notify(`User has been deactivated successfully`, 'success');
            if (onSuccess) {
              onSuccess(details);
            }
            setConfirmDialogOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(
              `An error occurred while deactivating user, Please try again`,
              'error'
            );
          }
        } else if (statusAction === 'REHIRE') {
          result = await rehireUser(userUpdateData as unknown as RehirePayload);
          setIsUpdateLoading(false);
          const responseStatus = result?.status?.toLowerCase();
          if (responseStatus === 'success') {
            notify(`User Re-Onboard has been enabled successfully`, 'success');
            if (onSuccess) {
              onSuccess(details);
            }
            setConfirmDialogOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(
              `An error occurred while enabling user Re-Onboard, Please try again`,
              'error'
            );
          }
        }
      } catch (error: any) {
        setIsUpdateLoading(false);
      }
    }
  };

  const updateUserClick = (action: string) => {
    setSubmitted(true);
    let payloadData:
      | DeactivatePayload
      | UserRevalidationPayload
      | RehirePayload
      | any = {};

    if (action === 'DEACTIVATE') {
      payloadData = {
        deactiveUserId: details?.userId,
        userType: actualUserType
          ? actualUserType?.map((item: any) => item?.toUpperCase())
          : []
      };
    }

    if (action === 'REHIRE') {
      payloadData = {
        reactivatingUserId: details?.userId,
        reactivatingEmail: details?.email,
        userType: actualUserType
          ? actualUserType?.map((item: any) => item?.toUpperCase())
          : []
      };
    }

    payloadData.remarks = remark;

    if (action === 'REVALIDATE') {
      payloadData = {
        revalidationUserId: details?.userId
      };
    }

    updatedUserAccount(action, payloadData);
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
    if (onChange) {
      onChange(false);
    }
  };

  const statusSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const dialogOpen = () => {
    setConfirmDialogOpen(true);
    if (onChange) onChange(true);
  };

  const checkIsTerminated = () => {
    const now = new Date();
    const terminatedAt = new Date(details?.terminationDate);
    /* compare is terminated 24h ago */
    const differenceInMilliseconds = now.getTime() - terminatedAt.getTime();
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    if (differenceInHours < 24) {
      setAllowed(false);
    } else {
      setAllowed(true);
    }

    const timeNow = moment();
    const duration = moment.duration(
      moment(details?.terminationDate).add(24, 'hours').diff(timeNow)
    );
    if (duration.asMilliseconds() <= 0) {
      setRemainingTime('You can raise a request now.');
    } else {
      setRemainingTime(
        `${duration.hours()} hours, ${duration.minutes()} minutes, ${duration.seconds()} seconds`
      );
    }
  };

  useEffect(() => {
    let interval: any;
    if (statusAction === 'REHIRE') {
      if (
        typeof allowed === 'undefined' &&
        details?.profileStatus?.toLowerCase() === 'terminated' &&
        details?.terminationDate
      ) {
        checkIsTerminated();
        interval = setInterval(() => {
          checkIsTerminated();
        }, 1000);
      } else {
        setAllowed(true);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      setAllowed(undefined);
    };
  }, [details, statusAction]);

  return (
    <>
      {buttonTitle && (
        <a
          className="base-margin-left btn btn--small btn--dark"
          onClick={dialogOpen}
        >
          {buttonTitle}
        </a>
      )}
      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <form name="statusform" onSubmit={statusSubmit}>
              <h4>
                {statusAction === 'REVALIDATE' ? (
                  <>
                    Are you sure to initiate revalidation for the user{' '}
                    <b className="text-size-16">
                      {details?.firstname} {details?.lastname} ({details?.email}
                      )
                    </b>{' '}
                    ?
                  </>
                ) : null}
                {statusAction === 'DEACTIVATE' ? (
                  <>
                    {isItemInArrayIgnoreCase(
                      details?.email,
                      details?.PA?.length ? details?.PA : []
                    ) ? (
                      <>
                        This user is a Partner Administrator for one or more
                        company accounts. Are you sure that you want to
                        deactivate the user <br />{' '}
                      </>
                    ) : (
                      <>
                        <span>
                          Are you sure to deactivate the user <br />
                        </span>
                      </>
                    )}{' '}
                    <b className="text-size-16">
                      {details?.firstname} {details?.lastname} ({details?.email}
                      )
                    </b>{' '}
                    ?
                  </>
                ) : null}
                {statusAction === 'REHIRE' && allowed ? (
                  <>
                    <span>
                      Are you sure to enable Re-Onboard for the user <br />{' '}
                    </span>
                    <b className="text-size-16">
                      {details?.firstname} {details?.lastname} ({details?.email}
                      )
                    </b>{' '}
                    ?
                  </>
                ) : null}
              </h4>

              {statusAction === 'REHIRE' && !allowed ? (
                <>
                  <div className="alert">
                    <div className="alert__message">
                      <p>
                        User{' '}
                        <span className="text-bold">
                          {details?.firstname} {details?.lastname} (
                          {details?.email})
                        </span>{' '}
                        profile has been terminated on{' '}
                        <b>
                          {details?.terminationDate
                            ? moment(new Date(details?.terminationDate)).format(
                                'MM-DD-YYYY, h:mm:ss A'
                              )
                            : '----'}
                        </b>
                      </p>
                      <p>
                        You can enable Re-Onboard only after profile has been
                        terminated for over 24 hours.
                      </p>
                      <p>
                        You can enable Re-Onboard in <b>{remainingTime}</b>.
                      </p>
                    </div>
                  </div>
                </>
              ) : null}

              {(statusAction === 'REHIRE' && allowed) ||
              statusAction === 'DEACTIVATE' ? (
                <>
                  <div>
                    <div
                      className={`form-group text-left ${
                        submitted && validation?.remark
                          ? 'form-group--error'
                          : ''
                      }`}
                    >
                      <div className="form-group__text">
                        <textarea
                          id="approver-comment"
                          rows={2}
                          value={remark || ''}
                          onChange={onRemarkChange}
                        ></textarea>
                        <label htmlFor="approver-comment">
                          <b>
                            Please provide a detailed justification{' '}
                            <span
                              className="text-danger"
                              title="this is a required field"
                            >
                              *
                            </span>
                            {submitted && validation?.remark && (
                              <>
                                <span className="text-danger text-size-12">
                                  {' '}
                                  {validation?.remark}
                                </span>
                              </>
                            )}
                          </b>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {isUpdateLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}
            </form>
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isUpdateLoading}
            >
              Cancel
            </button>

            {(statusAction === 'REHIRE' && allowed) ||
            statusAction !== 'REHIRE' ? (
              <button
                type="button"
                onClick={() => updateUserClick(statusAction)}
                className="btn btn--primary"
                disabled={isUpdateLoading}
              >
                Confirm
              </button>
            ) : null}
          </div>
        }
      />
    </>
  );
};
export default UserActionDialog;
