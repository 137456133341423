import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import notify from '../../../helpers/notification';
import httpUtil from '../../../helpers/interceptor';
import UserDetailDialog from '../../molecules/UserDetailDialog';
import Loader from '../../atoms/Loader';
import { hasAccess } from '../../../helpers';

import Filters from '../../molecules/Filters';
import './index.css';
import { Tooltip } from 'react-tooltip';
import ExcelExport from '../../atoms/ExcelExport';
import { noteToolTip } from '../../../helpers/common';

// const noteToolTip = (id, text) => {
//   return (
//     <>
//       <span
//         className="icon-exclamation-circle text-warning-alt"
//         data-tooltip-id={`tooltip-${id}`}
//         data-tooltip-place="bottom"
//       ></span>
//       <Tooltip id={`tooltip-${id}`}>
//         <span style={{ zIndex: 1111 }}>{text}</span>
//       </Tooltip>
//     </>
//   );
// };

const sortDataByDescendingDate = (data, dateField) => {
  return data.slice().sort((a, b) => {
    const dateA = new Date(a[dateField]).getTime();
    const dateB = new Date(b[dateField]).getTime();
    return dateB - dateA;
  });
};

const AddressTooltip = (props) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex)?.data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>Address Line 1: {data?.address1 || '-'}</span>
      </p>
      <p>
        <span>Address Line 2: {data?.address2 || '-'}</span>
      </p>
      <p>
        <span>City: {data?.city || '-'}</span>
      </p>
      <p>
        <span>State: {data?.state || '-'}</span>
      </p>
      <p>
        <span>Country: {data?.country || '-'}</span>
      </p>
      <p>
        <span>Postal Code: {data?.zip || '-'}</span>
      </p>
    </div>
  );
};

const SearchUsers = () => {
  const authData = useSelector((state) => state.auth);
  const { actualUserType } = authData;
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);

  const [rows, setRows] = React.useState([]);
  const [localFilter, setLocalFilter] = React.useState(null);
  const [columns, setColumns] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [row, setRow] = useState();
  const [viewLoader, setViewLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(undefined);
  const [filterValue, setFilterValue] = useState({
    searchUserType: 'cco',
    includeInactiveUsers: false
  });

  const [submittedValue, setSubmittedValue] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [getDataErrorMessage, setGetDataErrorMessage] = useState(undefined);
  const [validation, setValidation] = useState({});

  const [pageSize, setPageSize] = React.useState(10);

  useEffect(() => {
    setFilterValue((preVal) => {
      return {
        ...(preVal || {}),
        ...{
          filters: [
            {
              name: 'searchUserId',
              label: 'User Id',
              type: 'text',
              show: filterValue?.searchUserType === 'cec',
              value: ''
            },
            {
              name: 'userName',
              label: 'User Name',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'searchEmail',
              label: 'Email',
              type: 'text',
              show: filterValue?.searchUserType === 'cco',
              value: ''
            },
            {
              name: 'designation',
              label: 'Designation',
              type: 'select',
              options:
                filterValue?.searchUserType === 'cec'
                  ? [
                      {
                        value: 'Partner Account Manager',
                        label: 'Partner Account Manager'
                      },
                      { value: 'Cisco Sponsor', label: 'Cisco Sponsor' },
                      { value: 'Operational Admin', label: 'Operational Admin' }
                    ]
                  : [
                      {
                        value: 'Partner Administrator',
                        label: 'Partner Administrator'
                      },
                      { value: 'External User', label: 'External User' }
                    ],
              show: false,
              value: ''
            },
            {
              name: 'vendorName',
              label: 'Company Name',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'vendorId',
              label: 'Vendor Id',
              tooltip: noteToolTip(
                'vendorId',
                'Please enter complete Vendor ID'
              ),
              type: 'text',
              show: false,
              value: ''
            },

            {
              name: 'siteId',
              label: 'Site Id',
              type: 'text',
              show: false,
              value: ''
            },

            {
              name: 'primaryOrg',
              label: 'Primary Org',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'secOrg',
              label: 'Secondary Org',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'manager',
              label: 'Cisco Manager',
              type: 'text',
              show: false,
              value: ''
            },
            {
              name: 'status',
              label: 'SCREG Status',
              type: 'select',
              options: [
                { value: 'active', label: 'Active' },
                { value: 'inactive', label: 'Inactive' }
              ],
              show: false,
              value: ''
            }
          ]
            .filter((item) => {
              if (
                item.name === 'status' &&
                !hasAccess('users', 'statusFilter')
              ) {
                return false;
              }
              return true;
            })
            .filter((item) => {
              if (
                filterValue?.searchUserType === 'cec' &&
                ['manager', 'primaryOrg', 'secOrg', 'status'].includes(
                  item.name
                )
              ) {
                return false;
              }
              return true;
            })
        }
      };
    });
  }, [filterValue?.searchUserType]);

  useEffect(() => {
    if (gridApi) {
      if (status === 'SUCCESS' && rows && rows.length === 0) {
        gridApi.showNoRowsOverlay();
      } else if (status !== 'PENDING') {
        gridApi.hideOverlay();
      }
    }
  }, [rows, status, gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    //  'notContains', 'equals', 'notEqual'
    const filterParams = {
      suppressAndOrCondition: true,
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual']
    };

    const columnsList = [
      {
        headerName: 'User Id',
        field: 'userId',
        filter: true,
        filterParams,
        tooltipField: 'userId',
        pinned: 'left',
        width: 180
      },
      {
        headerName: 'User Name',
        field: 'firstname',
        filter: true,
        filterParams,
        headerTooltip: 'User Name',
        tooltipField: 'firstname',
        minWidth: 120,
        flex: 1,
        cellRenderer: (params) => {
          return params?.value
            ? params?.value + ' ' + params?.data?.lastname
            : '';
        }
      },
      {
        headerName: 'Email',
        field: 'email',
        filter: true,
        filterParams,
        headerTooltip: 'Email',
        tooltipField: 'email',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Designation',
        field: 'designationName',
        filter: true,
        filterParams,
        headerTooltip: 'Designation',
        tooltipField: 'designationName',
        minWidth: 120,
        flex: 1
      },
      {
        headerName: 'Company Name',
        field: 'mcocompany',
        filter: true,
        filterParams,
        headerTooltip: 'Company Name',
        tooltipField: 'mcocompany',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Vendor Id',
        field: 'vendorId',
        filter: true,
        filterParams,
        headerTooltip: 'Vendor Id',
        tooltipField: 'vendorId',
        width: 100
      },
      {
        headerName: 'Site Id',
        field: 'supplierSiteId',
        filter: true,
        tooltipField: 'supplierSiteId',
        headerTooltip: 'Site Id',
        width: 100
      },
      {
        headerName: 'Site Address',
        field: 'fullAddress',
        filter: true,
        tooltipField: 'fullAddress',
        headerTooltip: 'Site Address',
        minWidth: 180,
        flex: 1,
        tooltipComponent: AddressTooltip
      },
      {
        headerName: 'Primary Organization',
        field: 'primaryOrg',
        filter: true,
        filterParams,
        headerTooltip: 'Primary Organization',
        tooltipField: 'primaryOrg',
        minWidth: 140,
        flex: 1
      },

      {
        headerName: 'Secondary Organization',
        field: 'secOrg',
        filter: true,
        filterParams,
        headerTooltip: 'Secondary Organization',
        tooltipField: 'secOrg',
        minWidth: 140,
        flex: 1,
        cellRenderer: (params) => {
          return params?.value?.split(',')?.join(', ') || '-';
        }
      },
      {
        headerName: 'Cisco Manager',
        field: 'manager',
        filter: true,
        filterParams,
        headerTooltip: 'Cisco Manager',
        tooltipField: 'manager',
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'SCREG Status',
        field: 'profileStatus',
        filter: true,
        filterParams,
        headerTooltip: 'SCREG Status',
        tooltipField: 'profileStatus',
        width: 120,
        pinned: 'right',
        cellRenderer: (params) => {
          const fieldValue = params?.value?.toLowerCase();
          if (filterValue?.searchUserType === 'cec') {
            return (
              <span className="label label--success label--small label--bordered">
                Active
              </span>
            );
          }
          if (!fieldValue) return '';

          return (
            <>
              {fieldValue === 'active' ? (
                <span className="label label--success label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {fieldValue === 'inactive' ? (
                <span className="label label--warning label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {fieldValue !== 'active' && fieldValue !== 'inactive' ? (
                <span className="label label--light label--small label--bordered">
                  {params?.value}
                </span>
              ) : null}

              {params.data?.userRevalidationWorkflow?.toLowerCase() ===
              'inprocess' ? (
                <span className="qtr-margin-left">
                  Re-Validation:{' '}
                  <span className="">
                    {params.data?.userRevalidationWorkflow &&
                    params.data?.userRevalidationWorkflow?.toLowerCase() ===
                      'inprocess'
                      ? 'In Process'
                      : null}
                  </span>
                </span>
              ) : null}
            </>
          );
        }
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 80,
        pinned: 'right',
        headerTooltip: 'Action',
        cellRenderer: (params) => {
          return params.data ? (
            <>
              <a
                onClick={() => {
                  onClickDetailsDialogOpen(params.data);
                }}
              >
                View
              </a>
            </>
          ) : null;
        }
      }
    ]
      .map((item) => {
        let itemTemp = item;
        itemTemp.resizable = true;
        // itemTemp.floatingFilter = true;

        itemTemp.columnChooserParams = {
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
          suppressColumnFilter: false
        };

        itemTemp.menuTabs = ['filterMenuTab'];

        if (item.field !== 'action') {
          itemTemp.sortable = true;
          // itemTemp.flex = 1;
        } else {
          itemTemp.sortable = false;
        }

        itemTemp.suppressHeaderMenuButton = true;
        // itemTemp.sortable = false;
        // itemTemp.filter = false;
        return itemTemp;
      })
      .filter(({ field }) => {
        if (
          submittedValue?.searchUserType === 'cec' &&
          ['primaryOrg', 'secOrg', 'manager'].includes(field)
        ) {
          return false;
        }

        return true;
      });
    setColumns(columnsList);
  }, [gridApi, submittedValue?.searchUserType]);

  useEffect(() => {
    if (gridApi) {
      if (viewLoader) gridApi.showLoadingOverlay();
      else gridApi.hideOverlay();
    }
  }, [viewLoader]);

  const onClickDetailsDialogOpen = (requestRow) => {
    setRow(requestRow);

    if (submittedValue?.searchUserType === 'cec') {
      let apiUrl = '';
      apiUrl = '/api/am/dashboard/v1/internal/user/view';

      setViewLoader(true);
      httpUtil
        .post(apiUrl, {
          searchUserId: requestRow?.userId
        })
        .then((res) => {
          setRow((preVal) => {
            return { ...(preVal || {}), ...(res || {}) };
          });
          setDialogOpen(true);
          setViewLoader(false);
        })
        .catch((error) => {
          setStatus('ERROR');
          setViewLoader(false);
          const errorMessage = `An error occurred while getting user details ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        });
    } else {
      setDialogOpen(true);
    }
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const getOverlayNoRowsTemplate = () => {
    return 'No users found';
  };

  const handleChange = (event) => {
    if (event.target.name === 'includeInactiveUsers') {
      setFilterValue((preVal) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.checked }
        };
      });
    } else {
      setFilterValue((preVal) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.value }
        };
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (status === 'PENDING') {
      return;
    }
    if (!checkValidation()) {
      return;
    }

    setSubmittedValue(filterValue);
    setStatus('PENDING');
    searchUser();
    // }
  };
  useEffect(() => {
    if (localFilter) {
      let data = [];
      const filterModelKeys = Object.keys(localFilter.filterModel);
      setStatus('PENDING');
      data = rows.filter((row) => {
        const newRow = { ...row };
        newRow['firstname'] =
          row['firstname']?.trim() + ' ' + row['lastname']?.trim();
        let isVisile = false;
        for (const key of filterModelKeys) {
          isVisile = newRow[key]
            ?.trim()
            .includes(localFilter.filterModel[key].filter);
        }
        return isVisile;
      });
      setRows(data);
      localFilter.successCallback(data, data.length);
      setStatus('SUCCESS');
      setLocalFilter(null);
    }
  }, [localFilter?.filterModel]);

  const filterBody = useMemo(() => {
    const newFilterBody = {
      searchUserType: filterValue?.searchUserType,
      includeInactiveUsers: filterValue?.includeInactiveUsers
    };

    filterValue?.filters?.forEach((item) => {
      if (typeof item?.value !== 'undefined' && item?.show) {
        if (item?.type === 'select') {
          newFilterBody[item.name] = item?.value?.value;
        } else if (item?.value !== '') {
          newFilterBody[item.name] = item?.value;
        }
      }
    });

    return newFilterBody;
  }, [filterValue]);

  const body = useMemo(() => {
    return {
      userType: actualUserType
        ? actualUserType?.map((item) => item?.toUpperCase())
        : [],
      includeInactiveUsers: filterValue?.includeInactiveUsers || false,
      ...filterBody
    };
  }, [actualUserType, filterValue, filterBody]);

  const apiUrl = useMemo(() => {
    return filterValue?.searchUserType === 'cec'
      ? '/api/am/dashboard/v1/internal/user/search'
      : '/api/am/dashboard/v1/external/cco/user/search';
  }, [filterValue]);

  const searchUser = () => {
    // let filterBody = {
    //   searchUserType: filterValue?.searchUserType,
    //   includeInactiveUsers: filterValue?.includeInactiveUsers
    // };

    // filterValue?.filters?.forEach((item) => {
    //   if (typeof item?.value !== 'undefined' && item?.show) {
    //     if (item?.type === 'select') {
    //       filterBody[item.name] = item?.value?.value;
    //     } else if (item?.value !== '') {
    //       filterBody[item.name] = item?.value;
    //     }
    //   }
    //   //else if (filterValue?.searchUserType?.toLowerCase() === 'cco') {
    //   //  filterBody[item.name] = null;
    //   //}
    // });

    // const body = {
    //   userType: actualUserType
    //     ? actualUserType?.map((item) => item?.toUpperCase())
    //     : [],
    //   includeInactiveUsers: filterValue?.includeInactiveUsers || false,

    //   ...filterBody
    // };

    // let apiUrl = '/api/am/dashboard/v1/external/cco/user/search';
    // if (filterValue?.searchUserType === 'cec') {
    //   apiUrl = '/api/am/dashboard/v1/internal/user/search';
    // }
    setStatus('PENDING');

    httpUtil
      .post(`${apiUrl}`, { ...body, excelExport: false })
      .then((res) => {
        let usersListData =
          sortDataByDescendingDate(res?.data || [], 'updatedDate') || [];
        usersListData = usersListData.map((item) => {
          item.fullAddress = `${item?.address1 || ''}${
            item?.address2 ? ', ' + item?.address2 + '' : ''
          }${item?.city ? ', ' + item?.city + '' : ''}${
            item?.state ? ', ' + item?.state + '' : ''
          }${item?.country ? ', ' + item?.country + '' : ''}${
            item?.zip ? ', ' + item?.zip + '' : ''
          }`;
          return item;
        });
        setRows(usersListData);
        setStatus('SUCCESS');
      })
      .catch((error) => {
        setStatus('ERROR');
        const errorMessage = `An error occurred while searching user ${
          error?.response?.data?.error
            ? ': ' + error?.response?.data?.error
            : ''
        }`;
        notify(errorMessage, 'error');
      });
  };

  useEffect(() => {
    checkValidation();
  }, [filterValue]);

  const checkValidation = () => {
    let errors = [];

    if (filterValue?.filters?.filter((item) => item?.show)?.length > 3) {
      errors.push({
        name: 'filterCount',
        message: 'Not allowed to add more than 3 filters'
      });
    }

    filterValue?.filters?.forEach((item) => {
      let error = {};
      if (item?.show) {
        let fieldValue = '';
        if (item?.type === 'select') {
          fieldValue = item?.value?.value?.trim();
        } else {
          fieldValue = item?.value?.trim();
        }
        if (fieldValue === '' || typeof fieldValue === 'undefined') {
          error.name = item?.name;
          error.message = `${item?.label} is required`;
        } else if (item?.name === 'vendorId') {
          if (isNaN(fieldValue)) {
            error.name = item?.name;
            error.message = `${item?.label} should be numeric`;
          }
        }
      }
      if (Object.keys(error).length) {
        errors.push(error);
      }
    });

    setValidation((preVal) => {
      return {
        ...(preVal || {}),
        ...{ filters: errors }
      };
    });

    return errors.length === 0;
  };

  const onChangeFilter = (filtersValue) => {
    const filtersValueTemp = filtersValue || [];
    let statusFilterHasValue = !!filtersValueTemp?.find((filterItem) => {
      return filterItem.name === 'status' && filterItem.value;
    });

    let statusFilterShow = !!filtersValueTemp?.find((filterItem) => {
      return filterItem.name === 'status' && filterItem.show;
    });

    //  if (statusFilterShow) {
    // filtersValueTemp.map((filterItem) => {
    //   if (filterItem.name !== 'status') {
    //     filterItem.disabled = true;
    //     filterItem.show = false;
    //   }
    //   return filterItem;
    // });
    //  } else {
    filtersValueTemp.map((filterItem) => {
      filterItem.disabled = false;
      return filterItem;
    });
    //}

    setFilterValue((preVal) => {
      return {
        ...({
          ...preVal,
          ...{
            includeInactiveUsers: statusFilterShow
              ? false
              : preVal?.includeInactiveUsers
          }
        } || {}),
        filters: filtersValueTemp
      };
    });
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: false,
      minWidth: 100,
      filter: false,
      resizable: true
    };
  }, []);

  async function exportToExcel() {
    try {
      setLoading(true);
      const res = await httpUtil.post(`${apiUrl}`, {
        ...body,
        excelExport: true
      });
      setLoading(false);
      if (res?.data) {
        return res?.data.map(
          ({
            userId,
            firstname,
            lastname,
            email,
            mcocompany,
            vendorId,
            primaryOrg,
            secOrg,
            profileStatus,
            mcompanytype,
            dunsNo,
            PA,
            manager,
            country,
            designationName,
            supplierSiteId,
            address1,
            address2,
            city,
            state,
            zip
          }) => {
            const siteAddress = `${address1 ? address1 : ''}${
              address2 ? ', ' + address2 : ''
            }${city ? ', ' + city : ''}${state ? ', ' + state : ''}${
              country ? ', ' + country : ''
            }${zip ? ', ' + zip : ''}`;

            return {
              'User Id': userId,
              'First Name': firstname,
              'Last Name': lastname,
              Email: email,
              'Designation Name': designationName,
              ...(submittedValue?.searchUserType != 'cec' && {
                'Cisco Manager': manager
              }),
              'Site Address': siteAddress || '',
              'Company Name': mcocompany,
              'Vendor Id': vendorId,
              'Site Id': supplierSiteId == '0' ? '' : supplierSiteId,
              'Company Type': mcompanytype,
              ...(submittedValue?.searchUserType != 'cec' && {
                'Primary Organization': primaryOrg,
                'Secondary Organization': secOrg
              }),
              'DUNS Number': dunsNo,
              'Partner Administrators': Array.isArray(PA) ? PA?.join(', ') : PA,
              'SCREG Status':
                submittedValue?.searchUserType == 'cec'
                  ? 'ACTIVE'
                  : profileStatus
            };
          }
        );
      }
      return [{ error: 'No Data' }];
    } catch (err) {
      setLoading(false);
      return [{ error: err?.message }];
    }
  }

  return (
    <div className="">
      <div className="card qtr-margin-bottom">
        <div className="card-body">
          <form method="POST" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="filter-bar-row filter-tool">
                  <div className="filter-bar-item filter-bar-left filter-tool-item">
                    <div className="flex">
                      {hasAccess('users', 'readCECUsers') ? (
                        <>
                          <span className="base-margin-right half-margin-top">
                            User Type
                          </span>

                          <div className="base-margin-right half-margin-top">
                            <div className="form-group form-group--inline form-group--compressed">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="searchUserType"
                                  value="cec"
                                  checked={
                                    filterValue?.searchUserType === 'cec'
                                  }
                                  onChange={handleChange}
                                />
                                <span className="radio__input"></span>
                                <span className="radio__label">CEC</span>
                              </label>
                            </div>
                            <div className="form-group form-group--inline form-group--compressed">
                              <label className="radio">
                                <input
                                  type="radio"
                                  name="searchUserType"
                                  value="cco"
                                  checked={
                                    filterValue?.searchUserType === 'cco'
                                  }
                                  onChange={handleChange}
                                />
                                <span className="radio__input"></span>
                                <span className="radio__label">CCO</span>
                              </label>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>

                    {filterValue?.searchUserType === 'cco' ? (
                      <div className="">
                        <div
                          className="form-group form-group--inline form-group--compressed half-margin-top"
                          style={
                            filterValue?.filters?.find(
                              (filterItem) => filterItem?.name === 'status'
                            )?.show
                              ? { backgroundColor: '#eee' }
                              : {}
                          }
                        >
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name="includeInactiveUsers"
                              checked={
                                filterValue?.includeInactiveUsers === true
                              }
                              onChange={handleChange}
                              disabled={
                                filterValue?.filters?.find(
                                  (filterItem) => filterItem?.name === 'status'
                                )?.value
                              }
                            />
                            <span className="checkbox__input"></span>
                            <span className="checkbox__label">
                              Include inactive users in search{' '}
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="filter-bar-item filter-bar-center filter-tool-item">
                    <div className="row">
                      <div className="col-auto" style={{ maxWidth: '100%' }}>
                        <Filters
                          filters={filterValue?.filters || []}
                          onChange={onChangeFilter}
                          validation={
                            isSubmitted && validation?.filters
                              ? validation?.filters
                              : []
                          }
                          searchButton={
                            <>
                              <button
                                type="submit"
                                className="btn"
                                disabled={status === 'PENDING'}
                              >
                                Search
                              </button>
                              <div className="">
                                <span className="text-warning text-size-12">
                                  Note : Please select up to 3 filters{' '}
                                </span>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="filter-bar-item filter-bar-right filter-tool-item">
                    <div className="filter-bar-right-content">
                      {hasAccess('users', 'excelExport') && (
                        <div className="pull-right">
                          <ExcelExport
                            disabled={loading}
                            loading={loading}
                            fileName={`${submittedValue?.searchUserType}_users`}
                            getData={() => exportToExcel(rows)}
                            hidden={rows.length == 0}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {filterValue?.searchUserType === 'cec' ? (
                    <span className="text-warning text-size-12">
                      Note: Search result only includes PAM, Sponsor and
                      Operational Admin profiles.
                    </span>
                  ) : null}
                </div>

                {validation?.filters &&
                validation?.filters?.filter(
                  (filterItem) => filterItem?.name === 'filterCount'
                )?.length ? (
                  <div
                    className="alert alert--warning"
                    style={{ marginBottom: '8px' }}
                  >
                    <div className="alert__icon icon-warning-outline"></div>
                    <div className="alert__message">
                      {
                        validation?.filters?.find(
                          (filterItem) => filterItem?.name === 'filterCount'
                        )?.message
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>

      {!isSubmitted ? (
        <div>
          <div className="text-center">
            <span className="icon-file-text text-size-128 text-gray-300"></span>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {isSubmitted && status === 'SUCCESS' && rows.length === 0 ? (
        <div>
          <div className="text-center">
            <span className="icon-file text-size-128 text-gray-300"></span>
            <div className="text-secondary text-size-20 base-margin-bottom">
              No Results
            </div>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {status === 'PENDING' ? (
        <div className="dbl-padding " style={{ marginTop: '10%' }}>
          <div className="text-center">
            <Loader />
          </div>
        </div>
      ) : null}

      <div
        className={`ag-theme-alpine`}
        style={{
          height: 485,
          width: '100%',
          display:
            isSubmitted && status === 'SUCCESS' && rows.length
              ? 'block'
              : 'none'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          pagination={true}
          gridOptions={{ suppressContextMenu: true }}
          paginationPageSize={pageSize}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
          loadingOverlayComponent={Loader}
          enableCellTextSelection={true}
          suppressCellFocus={true}
          getRowStyle={getRowStyle}
          headerHeight={38}
          rowHeight={38}
          animateRows={true}
          tooltipShowDelay={0}
          loading={status === 'PENDING'}
          paginationPageSizeSelector={false}
        ></AgGridReact>
      </div>

      <UserDetailDialog
        userDetail={row || {}}
        open={dialogOpen}
        onChange={(dialogStatus) => {
          setDialogOpen(dialogStatus);
        }}
        searchUserType={submittedValue?.searchUserType}
      />
    </div>
  );
};
export default SearchUsers;
