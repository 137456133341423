import React, { useEffect, useState, ReactNode } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { SelectOption } from '../../molecules/Select';
import FullScreenLoader from '../../molecules/FullScreenLoader';
import httpUtil from '../../../helpers/interceptor';
import { commonConfig } from '../../../config/common';

import InlineEdit from '../../molecules/InlineEdit';
import PAMEdit from './PAMEdit';
import SponsorEdit from './SponsorEdit';
import OperationalAdminEdit from './OperationalAdminEdit';
import UsersListValue from '../../atoms/UsersListValue';

import { fetchCountries, fetchCustomers } from '../../../actions';
import {
  CompanyTypeDef,
  PaDetailsTypeDef,
  PamDetailsTypeDef,
  SponsorTypeDef,
  CountriesTypeDef,
  CustomersTypeDef
} from '../../pages/CompanyOnboard/companyOnboardEditTypes';
import notify from '../../../helpers/notification';
import UserPaginateSelect from '../../molecules/UserPaginateSelect/UserPaginateSelect';
import InvitePAUserEdit from '../InvitePAUserEdit/InvitePAUserEdit';
import { hasAccessForResourceRole } from '../../../helpers';
import OrgEdit from './OrgEdit/OrgEdit';
import { Tooltip } from 'react-tooltip';
import GroupEdit from './GroupEdit/GroupEdit';
import EditDomainSelect from '../../molecules/EditDomainSelect/EditDomainSelect';
import CompanyTypeEdit from './CompanyType/CompanyTypeEdit';
import {
  fetchExistingCompanySearch,
  sendInvitation,
  fetchSCIAMUsers
} from '../../../services';
import PAUserEdit from '../PAUserEdit/PAUserEdit';
import Loader from '../../atoms/Loader/Loader';
import CountriesEdit from './CountriesEdit/CountriesEdit';
import CustomersEdit from './Customers/CustomersEdit';
import CompanyTypeMandatoryDialog from './CompanyType/CompanyTypeMandatoryDialog';
import CompanyProcessDialog from '../../molecules/CompanyProcessDialog';
import CompanyReactivateDialog from '../../molecules/CompanyReactivateDialog';
import { arraysContainSameElements } from '../../../helpers/common';

export interface CompanyEditProps {
  companyId: string;
  editSource?: string;
  sideButton?: ReactNode;
}

const CompanyEdit = ({
  companyId,
  editSource = 'companyEdit',
  sideButton
}: CompanyEditProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id } = useParams();
  const authData = useSelector((state: any) => state?.auth);
  const { actualUserType, user, userActiveRolePermissions } = authData;

  const [companyData, setCompanyData] = useState<CompanyTypeDef>({
    lsCompanyNodesDetails: []
  });

  const [getCompanyStatus, setGetCompanyStatus] = useState<
    string | undefined
  >();

  const [mandatory, setMandatory] = useState<string[] | null>(null);
  useEffect(() => {
    if (companyId) {
      getCompanyDetails();
    }
  }, [companyId]);

  const getCompanyDetails = async () => {
    setGetCompanyStatus('PENDING');
    if (companyId) {
      fetchExistingCompanySearch(companyId)
        .then((res) => {
          if (!res) {
            navigate('/page-not-found');
          }
          setGetCompanyStatus('SUCCESS');
          setCompanyData(res);
        })
        .catch((err) => {
          setGetCompanyStatus('ERROR');
        });
    }
  };

  const { lsCompanyNodesDetails } = companyData;

  const [formValue, setFormValue] = React.useState<any | undefined>({
    companyType: '',
    isCompanyAssociate: 'no',
    vendorIndex: -1,
    paList: []
  });

  const [activeChildCompany, setActiveChildCompany] = React.useState<any>();
  const [isPaEdit, setIsPaEdit] = React.useState<boolean>(false);
  const [isEmailDomainsEdit, setIsEmailDomainsEdit] =
    React.useState<boolean>(false);

  const [validation, setValidation] = React.useState<any>({});
  const [openForEdit, setOpenForEdit] = React.useState<any>({});

  const operationalAdminList = useSelector(
    (state: any) => state.onboardCompany?.operationalAdmins
  );

  const [isVisibleInvitePA, setIsVisibleInvitePa] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    const currentCompanyNode = companyData
      ? companyData?.lsCompanyNodesDetails[formValue.vendorIndex]
      : {};
    setActiveChildCompany(currentCompanyNode);
  }, [companyData, formValue?.vendorIndex]);

  useEffect(() => {
    if (
      activeChildCompany?.onboardingStatus?.toLowerCase() ===
      'offboarding - inprogress'
    ) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
  }, [activeChildCompany]);

  useEffect(() => {
    if (formValue.vendorIndex != -1) {
      const currentCompanyNode = lsCompanyNodesDetails[formValue.vendorIndex];
      const {
        lsPAMDetails,
        lsCompanyOrgs,
        lsCompanyCountries,
        lsCompanyCustomers,
        lsSponsorAdmin,
        lsOPAdmin,
        companyGroupDetails,
        vendorId,
        supplierSiteId
      } = currentCompanyNode;

      let newVal = {
        countries: lsCompanyCountries?.map((res: CountriesTypeDef) => ({
          label: res.country,
          value: res.country
        })),
        customers: lsCompanyCustomers?.map((res: CustomersTypeDef) => ({
          label: res.customer,
          value: res.customer
        })),
        vendorId,
        siteId: supplierSiteId,
        groupName: companyGroupDetails?.companyGroupName,
        pam: lsPAMDetails?.map(
          ({ companyNodeId, userId, ...rest }: PamDetailsTypeDef) => ({
            ...rest,
            value: userId,
            label: userId,
            userId: userId
          })
        ),
        sponsor: lsSponsorAdmin?.map((res: SponsorTypeDef) => ({
          ...res,
          value: res.sponsor,
          label: res.sponsor,
          userId: res.pamUserId
        }))[0],
        primaryOperationalAdmin: lsOPAdmin
          ?.filter(({ primary }: any) => {
            return primary;
          })
          ?.map((item: any) => ({
            ...item,
            label: item.pamUserId,
            value: item.pamUserId,
            userId: item.pamUserId
          })),
        secondaryOperationalAdmin: lsOPAdmin
          ?.filter(({ primary }: any) => {
            return !primary;
          })
          ?.map((item: any) => ({
            ...item,
            label: item.pamUserId,
            value: item.pamUserId,
            userId: item.pamUserId
          })),
        organizations: lsCompanyOrgs?.map(({ org }: any) => org)
      };
      setFormValue((prev: any) => ({
        ...prev,
        ...newVal
      }));
    }
  }, [formValue.vendorIndex, refreshData]);

  useEffect(() => {
    setFormValue({
      ...formValue,
      ...{
        secondaryOperationalAdmin: operationalAdminList
      }
    });
  }, [operationalAdminList]);

  useEffect(() => {
    if (
      formValue?.paList &&
      formValue?.paList.length > 0 &&
      formValue?.paList[0].firstName != ''
    ) {
      setIsVisibleInvitePa(true);
    }
  }, [formValue?.paList]);

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCustomers());
    return () => {
      handleReset();
    };
  }, []);

  async function loadPAOptions(search: any, loadedOptions: any, { page }: any) {
    const emptyData = {
      options: [],
      hasMore: false,
      additional: {
        page: 1
      }
    };

    if (search.length < 3) {
      return emptyData;
    }

    try {
      const result: any = await fetchSCIAMUsers(
        page,
        search,
        formValue?.prevEmailDomains || [],
        companyId || ''
      );

      if (!result?.data?.length) {
        return emptyData;
      }
      return {
        options: result?.data?.map((res: any) => ({
          firstName: res?.firstName,
          lastName: res?.lastName,
          email: res?.email,
          userId: res?.userId,
          label: res?.email,
          value: res?.email
        })),
        hasMore: false,
        additional: {
          page: page + 1
        }
      };
    } catch (err) {
      return emptyData;
    }
  }

  const onPAChange = (selectedUsers: any) => {
    if (!companyData?.isFederatedCompany && !isValidPa(selectedUsers)) {
      return setFormValue((prevValue: any) => {
        return {
          ...(prevValue || {}),
          updatePaStatus: 'ERROR',
          error: {
            ...(prevValue?.error || {}),
            paValue: 'You cannot remove all nominated PA'
          }
        };
      });
    }

    setFormValue((prevValue: any) => ({
      ...prevValue,
      ...{
        pa: selectedUsers
      },
      error: {
        ...(prevValue?.error || {}),
        paValue: undefined
      }
    }));
  };

  const isValidPa = (newValue = []) => {
    const userIdArray = newValue?.map(({ userId }: any) => userId && userId);
    if (formValue?.prevPa?.length) {
      const previousActivePA = formValue?.prevPa
        ?.filter(
          (res: PaDetailsTypeDef) => res?.activeFlag?.toLowerCase() === 'y'
        )
        ?.map(({ userId }: PaDetailsTypeDef) => userId);
      if (previousActivePA.length) {
        const previousActivePAAfterUpdate = previousActivePA.filter(
          (item: string) => userIdArray.includes(item)
        );
        return !!previousActivePAAfterUpdate.length;
      }
    }
    return true;
  };

  const handleReset = () => {
    setValidation({});
    setFormValue({
      companyType: '',
      isCompanyAssociate: 'no',
      paList: []
    });
    dispatch({ type: 'POST_COMPANY_RESET' });
  };

  useEffect(() => {
    const {
      companyName,
      companyType,
      dunsNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      postalCode,
      domainNames: emailDomains,
      lsCompanyNodesDetails,
      countries,
      customers,
      lsPA,
      lsPARequestDetails
      // parentCompany
    } = companyData;

    // {
    //   companyName: string;
    //   companyType: string;
    //   domainNames: string[];
    //   city: string;
    //   state: string;
    //   dunsNumber: string;
    //   lsPA: {
    //     "firstName": string,
    //     "lastName": string,
    //     "email": string
    //   }[];
    // }
    if (companyName) {
      const paValue = lsPA?.map((item: PaDetailsTypeDef, i: number) => ({
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        label: item.email,
        value: item.email,
        userId: item.userId,
        activeFlag: item?.activeFlag,
        status: item?.status,
        comment: item?.comment
      }));
      const formattedEmailDomains = emailDomains?.filter(
        (emails: string, i: number) => !emailDomains!.includes(emails, i + 1)
      );
      const company = {
        companyName,
        selectedCompany: {
          companyName,
          companyType
        },
        vendorIndex:
          lsCompanyNodesDetails && lsCompanyNodesDetails.length > 0
            ? Number(formValue?.vendorIndex) == -1
              ? 0
              : Number(formValue?.vendorIndex)
            : -1,
        // vendorId: formValue['vendorId'],
        // supplierSiteId: formValue['supplierSiteId'],
        addressLine1,
        addressLine2,
        city,
        country,
        state,
        postalCode,
        // isCompanyAssociate: formValue['isCompanyAssociate'] || 'no',
        // parentCompanyId: formValue['parentCompany']?.companyId,
        // isMncaContractOnFileWithLegal: formValue['isMncaContractOnFileWithLegal'],
        companyType,
        dunsNumber,
        emailDomains: formattedEmailDomains,
        prevEmailDomains: formattedEmailDomains,
        // group: formValue['groupName'],
        pa: paValue,
        prevPa: paValue,
        ...(lsPARequestDetails && lsPARequestDetails.length > 0
          ? {
              paList: lsPARequestDetails.map((result) => ({
                ...result,
                isSubmitted: true
              }))
            }
          : {})
      };
      setFormValue({ ...formValue, ...company });
    }
  }, [companyData]);

  useEffect(() => {
    if (editSource === 'companyEdit') {
      validateMandatoryAttribute(true);
    }
  }, [activeChildCompany]);

  const validateMandatoryAttribute = (showError: boolean = false): string[] => {
    const mandatoryErrors = [];
    if (
      companyData?.isOrgMandatory &&
      !activeChildCompany?.lsCompanyOrgs?.length
    ) {
      mandatoryErrors.push('Company Orgs');
    }
    if (
      companyData?.isCountryMandatory &&
      !activeChildCompany?.lsCompanyCountries?.length
    ) {
      mandatoryErrors.push('Countries');
    }
    if (
      companyData?.isCustomerMandatory &&
      !activeChildCompany?.lsCompanyCustomers?.length
    ) {
      mandatoryErrors.push('Customers');
    }
    if (companyData?.activeFlag?.toLowerCase() === 'y') {
      if (
        hasAccessForResourceRole(
          [
            {
              roleName: companyData?.userGroupName || '',
              exist: !!userActiveRolePermissions?.find(
                (roleItem: any) => roleItem?.name === companyData?.userGroupName
              )
            }
          ],
          'company',
          'updateCompanyType'
        ) &&
        showError &&
        mandatoryErrors.length
      ) {
        // notify(
        //   ` Please add mandatory fields ( ${mandatoryErrors.join(' | ')} )`,
        //   'error'
        // );

        setMandatory(mandatoryErrors);
      }
    }
    return mandatoryErrors;
  };

  const handleUserInput = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const checkPAEmailExist = (value: any) => {
    const removedDomains = formValue?.emailDomains?.filter(
      (x: any) => !value?.includes(x)
    );
    if (removedDomains?.length > 0) {
      const allPas = formValue?.pa?.map((res: PaDetailsTypeDef) => res.email);

      return [
        removedDomains[0],
        allPas?.some((res: any) => res.endsWith(removedDomains[0]))
      ];
    }
    return [null, false];
  };

  const onSelectorChange = (
    newValue:
      | SelectOption
      | SelectOption[]
      | string[]
      | string
      | undefined
      | number,
    name: string
  ) => {
    if (name != 'emailDomains') {
      return setFormValue({
        ...formValue,
        ...{
          [name]: newValue
        }
      });
    }

    const [removedDomains, isExist] = checkPAEmailExist(newValue);
    if (!isExist) {
      setFormValue({
        ...formValue,
        ...{
          [name]: newValue
        }
      });
    } else {
      setFormValue((prevValue: any) => ({
        ...prevValue,
        error: {
          ...(prevValue?.error || {}),
          emailDomainsValue: `PA linked with ${removedDomains}`
        }
      }));
    }
  };

  /* Parent Company end */

  /* PAM select start */
  const onPAMSave = (changedValue: SelectOption[]) => {
    setCompanyData((preValue: any) => {
      const lsCompanyNodesDetailsTemp = preValue?.lsCompanyNodesDetails;
      lsCompanyNodesDetailsTemp[formValue.vendorIndex]['lsPAMDetails'] =
        changedValue?.map((item: any) => {
          return {
            userId: item?.value,
            email: item?.email,
            firstName: item?.firstName,
            lastName: item?.lastName,
            activeFlag: item?.activeFlag,
            status: item?.status,
            terminated: item?.terminated
          };
        });
      return {
        ...preValue,
        lsCompanyNodesDetails: lsCompanyNodesDetailsTemp
      };
    });
  };
  /* PAM select end */

  /* PA Invite select start */
  const onPAInviteSave = (changedValue: SelectOption[]) => {
    setCompanyData((preValue: any) => {
      return {
        ...preValue,
        lsPARequestDetails: changedValue
      };
    });
  };
  /* PA Invite select end */

  const handleChange = (event: any) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  /* Sponsor select start */
  const onSponsorSave = (changedValue: SelectOption[]) => {
    setCompanyData((preValue: any) => {
      const lsCompanyNodesDetailsTemp = preValue?.lsCompanyNodesDetails;
      lsCompanyNodesDetailsTemp[formValue.vendorIndex]['lsSponsorAdmin'] =
        changedValue?.map((item: any) => {
          return {
            sponsor: item?.value,
            email: item?.email,
            firstName: item?.firstName,
            lastName: item?.lastName
          };
        });
      return {
        ...preValue,
        lsCompanyNodesDetails: lsCompanyNodesDetailsTemp
      };
    });
  };

  /* Sponsor select end */

  const onOperationalAdminSave = (changedValue: any) => {
    const changedPrimaryOperationalAdmin = changedValue?.primaryOperationalAdmin
      ? changedValue?.primaryOperationalAdmin?.map((item: any) => {
          return {
            pamUserId: item?.value,
            email: item?.email,
            firstName: item?.firstName,
            lastName: item?.lastName,
            primary: true
          };
        })
      : [];
    const changedSecondaryOperationalAdmin =
      changedValue?.secondaryOperationalAdmin
        ? changedValue?.secondaryOperationalAdmin?.map((item: any) => {
            return {
              pamUserId: item?.value,
              email: item?.email,
              firstName: item?.firstName,
              lastName: item?.lastName,
              primary: false
            };
          })
        : [];

    setCompanyData((preValue: any) => {
      const lsCompanyNodesDetailsTemp = preValue?.lsCompanyNodesDetails;
      lsCompanyNodesDetailsTemp[formValue.vendorIndex]['lsOPAdmin'] = [
        ...changedPrimaryOperationalAdmin,
        ...changedSecondaryOperationalAdmin
      ];

      return {
        ...preValue,
        lsCompanyNodesDetails: lsCompanyNodesDetailsTemp
      };
    });
  };

  const onPaChangeEdit = (changedValue: boolean) => {
    setOpenForEdit(changedValue ? { pa: true } : {});
    setIsPaEdit(changedValue);
    setIsEmailDomainsEdit(false);
  };

  const onPaCancelEdit = (changedValue: boolean) => {
    setIsPaEdit(changedValue);
    setFormValue((prevValue: any) => {
      return {
        ...(prevValue || {}),
        pa: formValue?.prevPa,
        error: {
          ...(prevValue?.error || {}),
          paValue: ''
        }
      };
    });
  };

  const handlePaSave = () => {
    const addedPa: PaDetailsTypeDef[] = [];
    const removedPa = [];

    if (formValue?.pa.length == 0 && !companyData?.isFederatedCompany) {
      return setFormValue((prevValue: any) => {
        return {
          ...(prevValue || {}),
          updatePaStatus: 'ERROR',
          error: {
            ...(prevValue?.error || {}),
            paValue: 'Add Atleast one PA'
          }
        };
      });
    } else {
      const errorObj = [];
      formValue?.pa?.forEach((res: PaDetailsTypeDef) => {
        let isEmailValid = false;
        const selectedEmailDomains = formValue?.prevEmailDomains || [];
        const { email } = res;

        selectedEmailDomains?.forEach((mailDomainItem: string) => {
          if (email.endsWith(mailDomainItem)) {
            isEmailValid = true;
            return;
          }
        });

        if (!isEmailValid) {
          errorObj.push('PA Email does not match to email domain');
        }
      });

      if (errorObj.length) {
        return setFormValue((prevValue: any) => {
          return {
            ...(prevValue || {}),
            updatePaStatus: 'ERROR',
            error: {
              ...(prevValue?.error || {}),
              paValue: 'PA Email does not match to email domain'
            }
          };
        });
      }
    }

    if (!formValue?.prevPa || formValue?.prevPa.length == 0) {
      addedPa.push(...formValue.pa);
    } else {
      const userIdArray = formValue.pa?.map(
        ({ userId }: any) => userId && userId
      );
      const removedItem =
        formValue?.prevPa?.filter((res: PaDetailsTypeDef) => {
          return !userIdArray.includes(res.userId);
        }) || [];
      removedPa.push(...removedItem);

      const allUserIdArray = [
        ...(formValue.pa || []),
        ...(removedItem || []),
        ...(formValue?.prevPa || [])
      ]
        ?.map(({ userId }: any) => userId && userId)
        .filter(
          (element, index, arr) =>
            arr.indexOf(element) === arr.lastIndexOf(element)
        );

      const addedItem =
        formValue.pa?.filter((res: PaDetailsTypeDef) => {
          return allUserIdArray.includes(res.userId);
        }) || [];

      addedPa.push(...addedItem);
    }

    setFormValue((prevValue: any) => ({
      ...(prevValue || {}),
      updatePaStatus: 'PENDING',
      pa:
        formValue.pa?.map((item: PaDetailsTypeDef) => {
          let itemTemp = item;
          const isAddedPa = addedPa?.find((innerItem: PaDetailsTypeDef) => {
            return innerItem.userId === item.userId;
          });
          if (isAddedPa) {
            itemTemp.activeFlag = 'n';
            itemTemp.status = 'PENDING';
          }
          return itemTemp;
        }) || [],
      error: {
        ...(prevValue?.error || {}),
        paValue: undefined
      }
    }));

    const payload = {
      companyId: companyId,
      companyNodeId:
        companyData?.lsCompanyNodesDetails[formValue.vendorIndex].nodeId,
      addList: addedPa.map((res: PaDetailsTypeDef) => ({
        userId: res.userId,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email
      })),
      removeList: removedPa.map((res: PaDetailsTypeDef) => ({
        userId: res.userId,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email
      })),
      requestType: 'pa',
      userType: actualUserType
        ? actualUserType?.map((item: string) => item?.toUpperCase())
        : [],
      editSource: editSource
    };

    httpUtil
      .post(`/api/am/dashboard/v1/updatePaData`, payload)
      .then((_) => {
        setFormValue((prevValue: any) => ({
          ...(prevValue || {}),
          updatePaStatus: 'SUCCESS',
          prevPa: formValue.pa
        }));

        setCompanyData((preValue: any) => {
          const preValueTemp = preValue;
          preValue['lsPA'] = formValue?.pa?.map((item: any) => {
            return {
              userId: item?.value,
              email: item?.email,
              firstName: item.firstName,
              lastName: item.lastName,
              label: item.email,
              value: item.email,
              activeFlag: item?.activeFlag,
              status: item?.status,
              comment: item?.comment
            };
          });
          return {
            ...preValue,
            ...preValueTemp
          };
        });

        setIsPaEdit(false);
        notify('PA is updated successfully.', 'success');
      })
      .catch((error) => {
        setFormValue((prevValue: any) => ({
          ...(prevValue || {}),
          updatePaStatus: 'ERROR'
        }));
        const errorMessage = `An error occurred while updating Pa ${
          error?.response?.data?.error
            ? ': ' + error?.response?.data?.error
            : ''
        }`;
        notify(errorMessage, 'error');
      });
  };

  const onEmailDomainsChangeEdit = (changedValue: boolean) => {
    setOpenForEdit(changedValue ? { emailDomain: true } : {});
    setIsEmailDomainsEdit(changedValue);
    setIsPaEdit(false);
    setFormValue((prevValue: any) => ({
      ...prevValue,
      error: {
        ...(prevValue?.error || {}),
        emailDomainsValue: ''
      }
    }));
  };

  const onEmailDomainsCancelEdit = (changedValue: boolean) => {
    setIsEmailDomainsEdit(changedValue);
    setFormValue((prevValue: any) => {
      return {
        ...(prevValue || {}),
        emailDomains: formValue?.prevEmailDomains,
        error: {
          ...(prevValue?.error || {}),
          emailDomainsValue: ''
        }
      };
    });
  };

  const handleEmailDomainsSave = () => {
    if (!formValue?.emailDomains || formValue?.emailDomains.length == 0) {
      return setFormValue((prevValue: any) => {
        return {
          ...(prevValue || {}),
          updateEmailDomainsStatus: 'ERROR',
          error: {
            ...(prevValue?.error || {}),
            emailDomainsValue: 'Add Atleast one Email Domains'
          }
        };
      });
    }

    const addedDomain = [];
    const removedDomain = [];

    const domains = formValue?.emailDomains;

    const removedItem =
      formValue?.prevEmailDomains?.filter((res: string) => {
        return !domains.includes(res);
      }) || [];

    removedDomain.push(...removedItem);

    const allEmailDomains = [
      ...(formValue.emailDomains || []),
      ...(removedItem || []),
      ...(formValue?.prevEmailDomains || [])
    ].filter(
      (element, index, arr) => arr.indexOf(element) === arr.lastIndexOf(element)
    );

    const addedItem =
      formValue.emailDomains?.filter((res: string) => {
        return allEmailDomains.includes(res);
      }) || [];

    addedDomain.push(...addedItem);

    const payload = {
      companyId: companyId,
      companyNodeId:
        companyData?.lsCompanyNodesDetails[formValue.vendorIndex].nodeId,
      userType: actualUserType
        ? actualUserType?.map((item: string) => item?.toUpperCase())
        : [],
      addDomainList: addedItem,
      removeDomainList: removedDomain,
      requestType: 'EMAIL',
      editSource: editSource
    };

    setFormValue((prevValue: any) => ({
      ...(prevValue || {}),
      updateEmailDomainsStatus: 'PENDING'
    }));

    httpUtil
      .post(`/api/am/dashboard/v1/updateEmailDomain`, payload)
      .then((_) => {
        setIsEmailDomainsEdit(false);
        setFormValue((prevValue: any) => ({
          ...(prevValue || {}),
          updateEmailDomainsStatus: 'SUCCESS',
          prevEmailDomains: formValue.emailDomains
        }));
        notify('Email Domains is updated successfully.', 'success');
      })
      .catch((err) => {
        setFormValue((prevValue: any) => ({
          ...(prevValue || {}),
          updateEmailDomainsStatus: 'ERROR'
        }));
        const errorMessage = `An error occurred while updating Email Domains ${
          err?.response?.data?.error ? ': ' + err?.response?.data?.error : ''
        }`;
        notify(errorMessage, 'error');
      });
  };

  const updateFormValue = (newValue: any) => {
    setFormValue((prev: any) => ({ ...prev, ...newValue }));
  };

  const updateEditFieldStatus = (field: string, editStatus: boolean) => {
    setOpenForEdit(editStatus ? { [field]: true } : {});
    setIsEmailDomainsEdit(false);
    setIsPaEdit(false);
  };

  const refreshDataFunc = () => {
    setTimeout(() => {
      setRefreshData(Math.random());
    }, 1000);
  };

  const updateCompanyTypeFormValue = (companyData: any) => {
    setCompanyData(companyData);
    refreshDataFunc();
  };

  const onInvitationListChange = (paUserList: any) => {
    setFormValue({ ...formValue, ...{ newInvite: paUserList } });
  };

  const invitationOpen = () => {
    setFormValue({
      ...formValue,
      ...{ newInvite: [{ firstName: '', lastName: '', email: '' }] }
    });
    setIsInvitationOpen(true);
  };
  const invitationClose = () => {
    setFormValue({ ...formValue, ...{ newInvite: [] } });
    setIsInvitationOpen(false);
  };

  const handleSendInvitation = async () => {
    try {
      let errorObject: any = {};
      /* PA validation start */
      const selectedNewInvitation = formValue['newInvite'];
      let newPAErrorList: any = [];
      selectedNewInvitation?.forEach((item: any, index: number) => {
        let inviteEmail = item?.email?.trim();
        let itemError: any = [];
        if (!item?.firstName?.trim()) {
          itemError['firstName'] = 'First name is required';
        }
        if (!item?.lastName?.trim()) {
          itemError['lastName'] = 'Last name is required';
        }
        if (!inviteEmail) {
          itemError['email'] = 'Email is required';
        } else {
          let emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (!emailRegexp.test(inviteEmail)) {
            itemError['email'] = 'Invalid Email';
          } else {
            const selectedEmailDomains = formValue['emailDomains'] || [];
            let isEmailValid = false;
            selectedEmailDomains?.forEach((mailDomainItem: string) => {
              if (inviteEmail.endsWith(mailDomainItem)) {
                isEmailValid = true;
              }
            });
            if (!isEmailValid) {
              itemError['email'] =
                'Invitation email domain must be same as added email domain';
            } else {
              const findInvitationWithSameMail = selectedNewInvitation.filter(
                (findItem: any) => findItem?.email?.trim() === inviteEmail
              );

              if (findInvitationWithSameMail.length > 1) {
                itemError['email'] = 'Duplicate email is added';
              }
            }
          }
        }

        if (Object.keys(itemError)?.length) {
          newPAErrorList[index] = itemError;
        }
      });
      if (newPAErrorList?.length) {
        errorObject['newInvite'] = newPAErrorList;
      }

      if (Object.keys(errorObject).length == 0) {
        formValue.isLoadingInvitation = 'PENDING';
        setFormValue({
          ...formValue
        });

        const payload = {
          companyId: companyId,
          companyNodeId:
            companyData?.lsCompanyNodesDetails[formValue.vendorIndex].nodeId,
          userType: actualUserType
            ? actualUserType?.map((item: string) => item?.toUpperCase())
            : [],
          pa:
            formValue?.pa
              ?.filter(
                ({ activeFlag }: any) => activeFlag?.toLowerCase() == 'y'
              )
              ?.map((item: any) => {
                return item?.firstName + ' ' + item?.lastName;
              }) || [],
          pam:
            activeChildCompany?.lsPAMDetails?.map((item: any) => {
              return item?.firstName + ' ' + item?.lastName;
            }) || [],
          users:
            formValue?.newInvite?.map((item: any) => ({
              firstName: item.firstName,
              lastName: item.lastName,
              email: item.email
            })) || []
        };

        const result = await sendInvitation(payload);

        notify('On-boarding request has been sent successfully', 'success');

        formValue.isLoadingInvitation = 'SUCCESS';
        setFormValue({
          ...formValue
        });
        invitationClose();
      }

      setValidation({ ...errorObject });
    } catch (e: any) {
      formValue.isLoadingInvitation = 'ERROR';
      setFormValue({
        ...formValue
      });

      const errorMessage = `An error occurred while sending On-boarding Invitation ${
        e?.response?.data?.error ? ': ' + e?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
  };

  return (
    <div className="container">
      {getCompanyStatus === 'PENDING' && <FullScreenLoader />}
      {!companyData?.companyName && getCompanyStatus === 'SUCCESS' ? (
        <div className="alert alert--info">
          <div className="alert__icon icon-info-outline"></div>
          <div className="alert__message">No company found</div>
        </div>
      ) : null}

      {getCompanyStatus === 'ERROR' ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">
            An error occurred while fetching company Details, Please try again.
          </div>
        </div>
      ) : null}
      {companyData?.companyName && getCompanyStatus === 'SUCCESS' && (
        <>
          <div className="section">
            <div
              className="panel panel--bordered"
              style={{ marginBottom: '100px', overflow: 'visible' }}
            >
              <div style={{ width: '100%' }}>
                <div className=" ">
                  <React.Fragment>
                    <>
                      <div className="card half-padding-top half-padding-bottom">
                        <div
                          className="card-header"
                          //  style={{ display: 'none' }}
                        >
                          <div className="flex">
                            <span style={{ width: '100%' }} className="flex">
                              <h5 className="text-primary">Company Details</h5>
                              <span className="qtr-margin-left">
                                {' '}
                                <>
                                  {' '}
                                  {activeChildCompany?.activeFlag?.toLowerCase() ==
                                  'y' ? (
                                    <span className="label label--small  label--success  label--bordered">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="label label--small label--warning label--bordered">
                                      Inactive
                                    </span>
                                  )}
                                  {companyData?.revalidationStatus &&
                                  companyData?.revalidationStatus?.toLowerCase() ===
                                    'inprogress' ? (
                                    <span className="qtr-margin-left">
                                      Re-Validation:{' '}
                                      <span className="">
                                        {companyData?.revalidationStatus &&
                                        companyData?.revalidationStatus?.toLowerCase() ===
                                          'inprogress'
                                          ? 'In Process'
                                          : null}
                                      </span>
                                    </span>
                                  ) : null}
                                  {activeChildCompany?.onboardingStatus?.toLowerCase() !==
                                    'completed' &&
                                  activeChildCompany?.activeFlag?.toLowerCase() !==
                                    'y' ? (
                                    <>
                                      {activeChildCompany?.onboardingStatus
                                        ?.toLowerCase()
                                        .includes('offboarding') ? (
                                        <span className="qtr-margin-left">
                                          Off-boarding:{' '}
                                          <span className="">
                                            {activeChildCompany?.onboardingStatus &&
                                            activeChildCompany?.onboardingStatus?.toLowerCase() ===
                                              'offboarding - inprogress'
                                              ? 'In Process'
                                              : null}
                                            {activeChildCompany?.onboardingStatus &&
                                            activeChildCompany?.onboardingStatus?.toLowerCase() ===
                                              'offboarding - completed'
                                              ? 'Completed'
                                              : null}
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="qtr-margin-left">
                                          On-boarding:{' '}
                                          <span className="">
                                            {activeChildCompany?.onboardingStatus?.toLowerCase() ===
                                            'inprogress'
                                              ? 'In Process'
                                              : null}
                                            {activeChildCompany?.onboardingStatus &&
                                            activeChildCompany?.onboardingStatus?.toLowerCase() !==
                                              'inprogress'
                                              ? activeChildCompany?.onboardingStatus
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                activeChildCompany?.onboardingStatus
                                                  .slice(1)
                                                  .toLowerCase()
                                              : null}
                                          </span>
                                        </span>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              </span>

                              {editSource !== 'companyAccountReview' ? (
                                <>
                                  {activeChildCompany?.revalidationStatus?.toLowerCase() !==
                                    'inprogress' &&
                                  hasAccessForResourceRole(
                                    [
                                      {
                                        roleName:
                                          companyData?.userGroupName || '',
                                        exist:
                                          !!userActiveRolePermissions?.find(
                                            (roleItem: any) =>
                                              roleItem?.name ===
                                              companyData?.userGroupName
                                          )
                                      },
                                      {
                                        roleName: 'pam',
                                        exist:
                                          !!activeChildCompany?.lsPAMDetails
                                            ?.map((item: any) => item?.userId)
                                            .includes(user?.ccoid)
                                      }
                                    ],
                                    'company',
                                    'disableCompany'
                                  ) &&
                                  companyData?.activeFlag?.toLowerCase() ===
                                    'y' ? (
                                    <CompanyProcessDialog
                                      buttonTitle="Deactivate"
                                      companyDetail={{
                                        ...(activeChildCompany || {}),
                                        companyId: companyData?.companyMongoId,
                                        ...(companyData || {})
                                      }}
                                      onSave={() => {
                                        getCompanyDetails();
                                      }}
                                    />
                                  ) : null}

                                  {/* reactivate */}
                                  {hasAccessForResourceRole(
                                    [
                                      {
                                        roleName:
                                          companyData?.userGroupName || '',
                                        exist:
                                          !!userActiveRolePermissions?.find(
                                            (roleItem: any) =>
                                              roleItem?.name ===
                                              companyData?.userGroupName
                                          )
                                      },
                                      {
                                        roleName: 'pam',
                                        exist:
                                          !!activeChildCompany?.lsPAMDetails
                                            ?.map((item: any) => item?.userId)
                                            .includes(user?.ccoid)
                                      }
                                    ],
                                    'company',
                                    'reactivateCompany'
                                  ) &&
                                  companyData?.activeFlag?.toLowerCase() ===
                                    'n' &&
                                  ![
                                    'inprogress',
                                    'offboarding - inprogress',
                                    'offboarding - completed'
                                  ].includes(
                                    activeChildCompany?.onboardingStatus?.toLowerCase()
                                  ) &&
                                  !['inprogress'].includes(
                                    activeChildCompany?.revalidationStatus?.toLowerCase()
                                  ) ? (
                                    <CompanyReactivateDialog
                                      buttonTitle="Reactivate"
                                      companyDetail={{
                                        ...(activeChildCompany || {}),
                                        ...(companyData || {}),
                                        ...(activeChildCompany || {}),
                                        companyId: companyData?.companyMongoId
                                      }}
                                      onSave={() => {
                                        getCompanyDetails();
                                      }}
                                    />
                                  ) : null}
                                </>
                              ) : null}

                              {/* reactivate ends */}
                            </span>

                            {sideButton ? sideButton : null}
                            {editSource !== 'companyAccountReview' ? (
                              <Link
                                to="/company-manage"
                                state={{ fromSource: 'companyDetail' }}
                                style={{ width: '90px' }}
                                className="pull-right btn btn--ghost btn--small"
                              >
                                <span className="icon-arrow-left-tail"></span>{' '}
                                Back
                              </Link>
                            ) : null}
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div className="row">
                              <div className="col-md-3 border">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-type-company-name"
                                      className="text-weight-700 "
                                    >
                                      Company Name
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.companyName}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group half-margin-bottom half-margin-top">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="select-companyType"
                                      className=" text-weight-700 required"
                                    >
                                      <strong>Vendor Id</strong>
                                    </label>

                                    <div className="qtr-padding">
                                      {formValue?.vendorId}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group half-margin-bottom half-margin-top">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="select-companyType"
                                      className=" text-weight-700 required"
                                    >
                                      <strong>Site Id</strong>
                                    </label>

                                    <div className="qtr-padding">
                                      {formValue?.siteId}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className={`form-group`}>
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-dunsNumber"
                                      className="text-weight-700"
                                    >
                                      DUNS Number
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.dunsNumber || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-addressLine1"
                                      className="text-weight-700 "
                                    >
                                      Address Line 1
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.addressLine1 || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-addressLine2"
                                      className="text-weight-700 "
                                    >
                                      Address Line 2
                                    </label>

                                    <div className="form-value-holder">
                                      {formValue?.addressLine2 || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-city"
                                      className="text-weight-700 "
                                    >
                                      City
                                    </label>

                                    <div className="form-value-holder">
                                      {formValue?.city || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-state"
                                      className="text-weight-700 "
                                    >
                                      State
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.state || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-country"
                                      className="text-weight-700 "
                                    >
                                      Country
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.country || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group base-margin-bottom">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-postalCode"
                                      className="text-weight-700 "
                                    >
                                      Zip/ Postal Code
                                    </label>
                                    <div className="form-value-holder">
                                      {formValue?.postalCode || '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className={`form-group`}>
                                  <div className="form-group__text">
                                    {isEmailDomainsEdit &&
                                    formValue?.error?.emailDomainsValue ? (
                                      <div className="alert alert--warning">
                                        <div className="alert__icon icon-warning-outline"></div>
                                        <div className="alert__message">
                                          {formValue?.error?.emailDomainsValue}
                                        </div>
                                      </div>
                                    ) : null}

                                    <InlineEdit
                                      isDisableSave={arraysContainSameElements(
                                        formValue?.prevEmailDomains,
                                        formValue?.emailDomains
                                      )}
                                      isEditable={
                                        isEditable &&
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updateDomain'
                                        )
                                      }
                                      title={
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700"
                                        >
                                          Email Domains{' '}
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span className="text-size-12">
                                              (Example : @example.com){' '}
                                            </span>
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-domain`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-domain`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Type domain name and press
                                                  enter (example : @example.com){' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                      }
                                      readContent={
                                        <div className="edit-form-value-holder">
                                          {formValue?.emailDomains
                                            ?.map((item: any) => item)
                                            .join(', ')}
                                        </div>
                                      }
                                      isEditing={isEmailDomainsEdit}
                                      onChangeEdit={onEmailDomainsChangeEdit}
                                      onCancelEdit={onEmailDomainsCancelEdit}
                                      isLoading={
                                        formValue?.updateEmailDomainsStatus ===
                                        'PENDING'
                                      }
                                      editContent={
                                        <div className="edit-form-value-holder">
                                          <EditDomainSelect
                                            values={
                                              formValue?.emailDomains?.map(
                                                (item: any) => {
                                                  return {
                                                    value: item,
                                                    label: item
                                                  };
                                                }
                                              ) || []
                                            }
                                            onChange={(
                                              newValue: SelectOption[]
                                            ) => {
                                              // return
                                              onSelectorChange(
                                                newValue?.map(
                                                  (item) => item.value
                                                ) || [],
                                                'emailDomains'
                                              );
                                            }}
                                          />
                                        </div>
                                      }
                                      onSave={handleEmailDomainsSave}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group half-margin-bottom half-margin-top">
                                  <div className="form-group__text">
                                    <GroupEdit
                                      isEditable={
                                        editSource !== 'companyAccountReview' &&
                                        isEditable &&
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updateGroupName'
                                        )
                                      }
                                      title={
                                        <label
                                          htmlFor="select-companyType"
                                          className=" text-weight-700"
                                        >
                                          <strong>Group Name</strong>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-groupName`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-groupName`}>
                                                <>
                                                  <div
                                                    style={{ zIndex: '111' }}
                                                  >
                                                    Start Typing something you
                                                    can either select from list
                                                    or create new group by
                                                    clicking on create option
                                                  </div>

                                                  <div
                                                    style={{ zIndex: '111' }}
                                                  >
                                                    Only Alphanumeric character
                                                    is allowed with underscore
                                                    and dash as special
                                                    characters
                                                  </div>
                                                </>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                      }
                                      open={openForEdit['groupName']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus(
                                          'groupName',
                                          openStatus
                                        )
                                      }
                                      updateGroupFormValue={updateFormValue}
                                      value={formValue?.groupName}
                                      userType={actualUserType}
                                      companyType={companyData?.companyType}
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-3 half-padding-top half-padding-bottom">
                                {editSource !== 'companyAccountReview' &&
                                isEditable &&
                                hasAccessForResourceRole(
                                  [
                                    {
                                      roleName:
                                        companyData?.userGroupName || '',
                                      exist: !!userActiveRolePermissions?.find(
                                        (roleItem: any) =>
                                          roleItem?.name ===
                                          companyData?.userGroupName
                                      )
                                    }
                                  ],
                                  'company',
                                  'updateCompanyType'
                                ) ? (
                                  <CompanyTypeEdit
                                    open={openForEdit['companyType']}
                                    onOpenChange={(openStatus: boolean) =>
                                      updateEditFieldStatus(
                                        'companyType',
                                        openStatus
                                      )
                                    }
                                    title={
                                      <label
                                        htmlFor="input-type-company-name"
                                        className="text-weight-700"
                                      >
                                        Company Type
                                        <span className="required"></span>{' '}
                                      </label>
                                    }
                                    updateCompanyTypeFormValue={
                                      updateCompanyTypeFormValue
                                    }
                                    userType={actualUserType}
                                    companyType={companyData?.companyType}
                                    companyId={companyId}
                                    companyNodeId={activeChildCompany?.nodeId}
                                    value={{
                                      companyType: companyData?.companyType,
                                      isOrgMandatory:
                                        companyData?.isOrgMandatory,
                                      isCountryMandatory:
                                        companyData?.isCountryMandatory,
                                      isCustomerMandatory:
                                        companyData?.isCustomerMandatory,
                                      isOrgShow: companyData?.isOrgShow,
                                      isCountryShow: companyData?.isCountryShow,
                                      isCustomerShow:
                                        companyData?.isCustomerShow,
                                      companyName: companyData?.companyName,
                                      organizations: formValue?.organizations,
                                      countries: formValue?.countries || [],
                                      customers: formValue?.customers || [],
                                      primaryOperationalAdmin:
                                        activeChildCompany?.lsOPAdmin
                                          ?.filter(({ primary }: any) => {
                                            return primary;
                                          })
                                          ?.map((item: any) => ({
                                            ...item,
                                            label: item.pamUserId,
                                            value: item.pamUserId,
                                            userId: item.pamUserId
                                          })) || [],
                                      secondaryOperationalAdmin:
                                        activeChildCompany?.lsOPAdmin
                                          ?.filter(({ primary }: any) => {
                                            return !primary;
                                          })
                                          ?.map((item: any) => ({
                                            ...item,
                                            label: item.pamUserId,
                                            value: item.pamUserId,
                                            userId: item.pamUserId
                                          })) || [],

                                      pamList: activeChildCompany?.lsPAMDetails
                                        ?.length
                                        ? activeChildCompany?.lsPAMDetails?.map(
                                            (item: any) => item?.userId
                                          )
                                        : [],
                                      sponsorLis: activeChildCompany
                                        ?.lsSponsorAdmin?.length
                                        ? activeChildCompany?.lsSponsorAdmin?.map(
                                            (item: any) => item?.sponsor
                                          )
                                        : [],
                                      companyNodeId: activeChildCompany?.nodeId
                                    }}
                                  />
                                ) : (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <div className="form-group__text">
                                          <label
                                            htmlFor="input-type-company-search-name"
                                            className={`text-weight-700`}
                                          >
                                            Company Type
                                          </label>
                                          <div className="edit-form-value-holder">
                                            {formValue?.companyType || '-'}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {companyData?.isCountryShow ? (
                                <>
                                  <div className="col-md-3 half-padding-top half-padding-bottom">
                                    <CountriesEdit
                                      title={
                                        <label
                                          htmlFor="input-type-company-search-name"
                                          className="text-weight-700"
                                        >
                                          Countries
                                          {companyData?.isCountryMandatory ? (
                                            <span className="required"></span>
                                          ) : null}
                                        </label>
                                      }
                                      isEditable={
                                        (isEditable &&
                                          hasAccessForResourceRole(
                                            [
                                              {
                                                roleName:
                                                  companyData?.userGroupName ||
                                                  '',
                                                exist:
                                                  !!userActiveRolePermissions?.find(
                                                    (roleItem: any) =>
                                                      roleItem?.name ===
                                                      companyData?.userGroupName
                                                  )
                                              }
                                            ],
                                            'company',
                                            'updateCompanyType'
                                          )) ||
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updateCountries'
                                        )
                                      }
                                      open={openForEdit['countries']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus(
                                          'countries',
                                          openStatus
                                        )
                                      }
                                      updateCountriesFormValue={updateFormValue}
                                      userType={actualUserType}
                                      companyType={companyData?.companyType}
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      value={formValue?.countries || []}
                                      required={companyData?.isCountryMandatory}
                                      editSource={editSource}
                                    />
                                  </div>{' '}
                                </>
                              ) : null}
                              {companyData?.isCustomerShow ? (
                                <>
                                  <div className="col-md-3 half-padding-top half-padding-bottom">
                                    <CustomersEdit
                                      title={
                                        <label
                                          htmlFor="input-type-company-search-name"
                                          className="text-weight-700"
                                        >
                                          Customers
                                          {companyData?.isCustomerMandatory ? (
                                            <span className="required"></span>
                                          ) : null}
                                        </label>
                                      }
                                      isEditable={
                                        (isEditable &&
                                          hasAccessForResourceRole(
                                            [
                                              {
                                                roleName:
                                                  companyData?.userGroupName ||
                                                  '',
                                                exist:
                                                  !!userActiveRolePermissions?.find(
                                                    (roleItem: any) =>
                                                      roleItem?.name ===
                                                      companyData?.userGroupName
                                                  )
                                              }
                                            ],
                                            'company',
                                            'updateCompanyType'
                                          )) ||
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updateCustomers'
                                        )
                                      }
                                      open={openForEdit['customers']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus(
                                          'customers',
                                          openStatus
                                        )
                                      }
                                      updateCustomersFormValue={updateFormValue}
                                      userType={actualUserType}
                                      companyType={companyData?.companyType}
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      value={formValue?.customers || []}
                                      required={
                                        companyData?.isCustomerMandatory
                                      }
                                      editSource={editSource}
                                    />
                                  </div>
                                </>
                              ) : null}
                              {companyData?.isOrgShow ? (
                                <div className="col-md-3 half-padding-top half-padding-bottom">
                                  {isEditable &&
                                  (hasAccessForResourceRole(
                                    [
                                      {
                                        roleName:
                                          companyData?.userGroupName || '',
                                        exist:
                                          !!userActiveRolePermissions?.find(
                                            (roleItem: any) =>
                                              roleItem?.name ===
                                              companyData?.userGroupName
                                          )
                                      }
                                    ],
                                    'company',
                                    'updateCompanyType'
                                  ) ||
                                    hasAccessForResourceRole(
                                      [
                                        {
                                          roleName:
                                            companyData?.userGroupName || '',
                                          exist:
                                            !!userActiveRolePermissions?.find(
                                              (roleItem: any) =>
                                                roleItem?.name ===
                                                companyData?.userGroupName
                                            )
                                        },
                                        {
                                          roleName: 'pam',
                                          exist:
                                            !!activeChildCompany?.lsPAMDetails
                                              ?.map((item: any) => item?.userId)
                                              .includes(user?.ccoid)
                                        }
                                      ],
                                      'company',
                                      'updateOrganization'
                                    )) ? (
                                    <OrgEdit
                                      open={openForEdit['organization']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus(
                                          'organization',
                                          openStatus
                                        )
                                      }
                                      isOrgMandatory={
                                        companyData?.isOrgMandatory
                                      }
                                      updateOrgFormValue={updateFormValue}
                                      userType={actualUserType}
                                      companyType={companyData?.companyType}
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      value={formValue?.organizations || []}
                                      editSource={editSource}
                                    />
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-group__text">
                                            <label
                                              htmlFor="input-type-company-search-name"
                                              className="text-weight-700"
                                            >
                                              Company Orgs
                                            </label>
                                            <div className="edit-form-value-holder">
                                              {formValue?.organizations?.length
                                                ? formValue?.organizations
                                                    ?.map((item: any) => item)
                                                    .join(', ')
                                                : '-'}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card half-margin-top  half-margin-bottom">
                        <div className="card-header">
                          <h5 className="text-primary">Admin Details</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="form-group">
                                <div className="form-group__text">
                                  {isPaEdit && formValue?.error?.paValue ? (
                                    <div className="alert alert--warning">
                                      <div className="alert__icon icon-warning-outline"></div>
                                      <div className="alert__message">
                                        {formValue?.error?.paValue}
                                      </div>
                                    </div>
                                  ) : null}

                                  <InlineEdit
                                    isDisableSave={arraysContainSameElements(
                                      formValue?.prevPa,
                                      formValue?.pa
                                    )}
                                    isEditable={
                                      isEditable &&
                                      hasAccessForResourceRole(
                                        [
                                          {
                                            roleName:
                                              companyData?.userGroupName || '',
                                            exist:
                                              !!userActiveRolePermissions?.find(
                                                (roleItem: any) =>
                                                  roleItem?.name ===
                                                  companyData?.userGroupName
                                              )
                                          },
                                          {
                                            roleName: 'pam',
                                            exist:
                                              !!activeChildCompany?.lsPAMDetails
                                                ?.map(
                                                  (item: any) => item?.userId
                                                )
                                                .includes(user?.ccoid)
                                          }
                                        ],
                                        'company',
                                        'updatePA'
                                      )
                                    }
                                    title={
                                      <label
                                        htmlFor="select-additionalOrganization"
                                        className="text-weight-700"
                                      >
                                        Partner Administrators
                                        <span
                                          className="icon-info-outline text-info qtr-margin-left"
                                          data-tooltip-id={`tooltip-search-example`}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <Tooltip
                                            id={`tooltip-search-example`}
                                          >
                                            <span style={{ zIndex: '1111' }}>
                                              Search by User Name, Userid or
                                              Email
                                              <br />
                                              Scroll to bottom to load more
                                              users
                                            </span>
                                          </Tooltip>
                                        </span>
                                      </label>
                                    }
                                    readContent={
                                      <div className="edit-form-value-holder">
                                        <UsersListValue
                                          users={
                                            formValue?.pa?.map((item: any) => {
                                              return {
                                                userId: item?.email,
                                                email: item?.email,
                                                firstName: item?.firstName,
                                                lastName: item?.lastName,
                                                activeFlag: item?.activeFlag,
                                                status: item?.status,
                                                remarks: item?.remarks
                                              };
                                            }) || []
                                          }
                                        />
                                      </div>
                                    }
                                    isEditing={isPaEdit}
                                    onChangeEdit={onPaChangeEdit}
                                    onCancelEdit={onPaCancelEdit}
                                    isLoading={
                                      formValue?.updatePaStatus === 'PENDING'
                                    }
                                    editContent={
                                      <div className="edit-form-value-holder">
                                        <UserPaginateSelect
                                          id="paInput"
                                          value={formValue?.pa}
                                          name="pa"
                                          isMulti
                                          placeholder="Search PA...."
                                          loadOptions={loadPAOptions}
                                          onChange={onPAChange}
                                          additional={{
                                            page: 1
                                          }}
                                        />
                                      </div>
                                    }
                                    onSave={handlePaSave}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {isEditable &&
                                hasAccessForResourceRole(
                                  [
                                    {
                                      roleName:
                                        companyData?.userGroupName || '',
                                      exist: !!userActiveRolePermissions?.find(
                                        (roleItem: any) =>
                                          roleItem?.name ===
                                          companyData?.userGroupName
                                      )
                                    },
                                    {
                                      roleName: 'pam',
                                      exist: !!activeChildCompany?.lsPAMDetails
                                        ?.map((item: any) => item?.userId)
                                        .includes(user?.ccoid)
                                    }
                                  ],
                                  'company',
                                  'updatePAInvite'
                                ) &&
                                !isVisibleInvitePA && (
                                  <div className="row">
                                    <div className="col md-12 ">
                                      <span className="text-bold">Note:</span>
                                      <span className="text-danger">
                                        {' '}
                                        You can add more PA by sending them
                                        invite link by clicking{' '}
                                        <a
                                          className="text-link"
                                          onClick={() =>
                                            setIsVisibleInvitePa(true)
                                          }
                                        >
                                          here
                                        </a>{' '}
                                        to add more PA
                                      </span>
                                    </div>
                                  </div>
                                )}
                              {isEditable && isVisibleInvitePA && (
                                <div className="">
                                  <div className="base-margin-bottom">
                                    <InvitePAUserEdit
                                      isEditable={hasAccessForResourceRole(
                                        [
                                          {
                                            roleName:
                                              companyData?.userGroupName || '',
                                            exist:
                                              !!userActiveRolePermissions?.find(
                                                (roleItem: any) =>
                                                  roleItem?.name ===
                                                  companyData?.userGroupName
                                              )
                                          },
                                          {
                                            roleName: 'pam',
                                            exist:
                                              !!activeChildCompany?.lsPAMDetails
                                                ?.map(
                                                  (item: any) => item?.userId
                                                )
                                                .includes(user?.ccoid)
                                          }
                                        ],
                                        'company',
                                        'updatePAInvite'
                                      )}
                                      emailDomains={
                                        formValue?.emailDomains
                                          ? formValue?.emailDomains
                                          : []
                                      }
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      setIsVisibleInvitePa={() => {
                                        setIsVisibleInvitePa((prev) => !prev);
                                      }}
                                      paUserList={formValue?.paList}
                                      title="Invite PA"
                                      maxAddLimitSkip={true}
                                      editSource={editSource}
                                      onSave={onPAInviteSave}
                                    />

                                    {formValue?.paList &&
                                    formValue?.paList?.length > 1 &&
                                    validation['companyDetails']?.paList ? (
                                      <div className="alert alert--warning">
                                        <div className="alert__icon icon-warning-outline"></div>
                                        <div className="alert__message">
                                          One PA is mandatory, You can remove
                                          2nd PA if not require
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {editSource !== 'companyAccountReview' &&
                            isEditable &&
                            !!activeChildCompany?.lsPAMDetails
                              ?.map((item: any) => item?.userId)
                              .includes(user?.ccoid) &&
                            companyData?.activeFlag?.toLowerCase() == 'y' && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="base-margin-bottom">
                                    <div className="half-padding-top half-padding-bottom">
                                      <span className="text-bold">Note: </span>
                                      <span className="text-danger">
                                        Onboarding Invitation for user to
                                        register for this company by sending
                                        them invite link by clicking{' '}
                                        <a onClick={invitationOpen}>here</a>
                                      </span>{' '}
                                      {isInvitationOpen ? (
                                        <button
                                          type="button"
                                          className="btn btn--small"
                                          onClick={invitationClose}
                                        >
                                          Cancel invite
                                        </button>
                                      ) : null}
                                    </div>
                                    {isInvitationOpen && (
                                      <div>
                                        <PAUserEdit
                                          paUserList={formValue?.newInvite}
                                          validation={
                                            validation && validation?.newInvite
                                              ? validation?.newInvite
                                              : []
                                          }
                                          title={'Send Invitation'}
                                          onChange={(paUserList: any) => {
                                            onInvitationListChange(paUserList);
                                          }}
                                        />

                                        <div className="card">
                                          <div className="card-header">
                                            <h5 className="text-size-16 text-weight-700 text-right">
                                              <button
                                                type="button"
                                                className="btn btn--small"
                                                onClick={handleSendInvitation}
                                              >
                                                Send Invitation
                                              </button>
                                            </h5>
                                          </div>
                                        </div>
                                        {formValue?.isLoadingInvitation ==
                                          'PENDING' && (
                                          <div className="center-holder-wrap">
                                            <Loader />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                          <div className="row half-padding-bottom">
                            <div className="col-md-6">
                              <div className={`form-group`}>
                                <div className="form-group__text">
                                  <div>
                                    <PAMEdit
                                      isEditable={
                                        isEditable &&
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updatePAM'
                                        )
                                      }
                                      title={
                                        <label
                                          htmlFor="input-type-company-search-name"
                                          className="text-weight-700"
                                        >
                                          Partner Account Managers
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-pam`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-pam`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Search by first name,last
                                                  name,email and user ID.{' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                      }
                                      open={openForEdit['pam']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus('pam', openStatus)
                                      }
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      operationalAdminList={
                                        activeChildCompany?.lsOPAdmin?.length
                                          ? activeChildCompany?.lsOPAdmin?.map(
                                              (item: any) => item?.pamUserId
                                            )
                                          : []
                                      }
                                      sponsorList={
                                        activeChildCompany?.lsSponsorAdmin
                                          ?.length
                                          ? activeChildCompany?.lsSponsorAdmin?.map(
                                              (item: any) => item?.sponsor
                                            )
                                          : []
                                      }
                                      value={
                                        activeChildCompany?.lsPAMDetails?.length
                                          ? activeChildCompany?.lsPAMDetails?.map(
                                              (item: any) => {
                                                return {
                                                  value: item?.userId,
                                                  label: item?.userId,
                                                  userId: item?.userId,
                                                  email: item?.email,
                                                  firstName: item?.firstName,
                                                  lastName: item?.lastName,
                                                  activeFlag: item?.activeFlag,
                                                  status: item?.status,
                                                  remarks: item?.remarks,
                                                  terminated: item?.terminated,
                                                  userRole: 'pam'
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      onSave={onPAMSave}
                                      editSource={editSource}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={`form-group`}>
                                <div className="form-group__text">
                                  <div>
                                    <SponsorEdit
                                      isEditable={
                                        isEditable &&
                                        hasAccessForResourceRole(
                                          [
                                            {
                                              roleName:
                                                companyData?.userGroupName ||
                                                '',
                                              exist:
                                                !!userActiveRolePermissions?.find(
                                                  (roleItem: any) =>
                                                    roleItem?.name ===
                                                    companyData?.userGroupName
                                                )
                                            },
                                            {
                                              roleName: 'pam',
                                              exist:
                                                !!activeChildCompany?.lsPAMDetails
                                                  ?.map(
                                                    (item: any) => item?.userId
                                                  )
                                                  .includes(user?.ccoid)
                                            },
                                            {
                                              roleName: 'sponsor',
                                              exist:
                                                !!activeChildCompany?.lsSponsorAdmin
                                                  ?.map(
                                                    (item: any) => item?.sponsor
                                                  )
                                                  .includes(user?.ccoid)
                                            }
                                          ],
                                          'company',
                                          'updateSponsor'
                                        )
                                      }
                                      title={
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700"
                                        >
                                          Cisco Sponsor
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-sponsor`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-sponsor`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Search by first name,last
                                                  name,email and user ID.{' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                      }
                                      open={openForEdit['sponsor']}
                                      onOpenChange={(openStatus: boolean) =>
                                        updateEditFieldStatus(
                                          'sponsor',
                                          openStatus
                                        )
                                      }
                                      companyId={companyId}
                                      companyNodeId={activeChildCompany?.nodeId}
                                      operationalAdminList={
                                        activeChildCompany?.lsOPAdmin?.length
                                          ? activeChildCompany?.lsOPAdmin?.map(
                                              (item: any) => item?.pamUserId
                                            )
                                          : []
                                      }
                                      pamList={
                                        activeChildCompany?.lsPAMDetails?.length
                                          ? activeChildCompany?.lsPAMDetails?.map(
                                              (item: any) => item?.userId
                                            )
                                          : []
                                      }
                                      value={
                                        activeChildCompany?.lsSponsorAdmin
                                          ?.length
                                          ? activeChildCompany?.lsSponsorAdmin?.map(
                                              (item: any) => {
                                                return {
                                                  value: item?.sponsor,
                                                  label: item?.sponsor,
                                                  userId: item?.sponsor,
                                                  email: item?.email,
                                                  firstName: item?.firstName,
                                                  lastName: item?.lastName,
                                                  terminated: item?.terminated,
                                                  userRole: 'sponsor'
                                                };
                                              }
                                            )
                                          : []
                                      }
                                      onSave={onSponsorSave}
                                      editSource={editSource}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card half-padding-top half-margin-top">
                        <div className="card-header">
                          <div className="form-group form-group--inline">
                            <div className={`form-group__text`}>
                              <label htmlFor="select-companySearchBy">
                                <span className="text-size-16 text-weight-700 text-primary">
                                  Operational Admin Details
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {validation &&
                            validation['companyDetails']?.operationalAdmin && (
                              <div className="col-md-12">
                                <div className="alert alert--warning">
                                  <div className="alert__icon icon-warning-outline"></div>
                                  <div className="alert__message">
                                    {
                                      validation['companyDetails']
                                        ?.operationalAdmin?.message
                                    }
                                    {' - ' +
                                      validation[
                                        'companyDetails'
                                      ]?.operationalAdmin?.users
                                        ?.map(
                                          (item: SelectOption) => item.value
                                        )
                                        .join(' ,')}
                                  </div>
                                </div>
                              </div>
                            )}

                          <div>
                            <div className="row half-padding-top half-padding-bottom">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-group__text">
                                    <div>
                                      <OperationalAdminEdit
                                        isEditable={
                                          editSource !==
                                            'companyAccountReview' &&
                                          isEditable &&
                                          hasAccessForResourceRole(
                                            [
                                              {
                                                roleName:
                                                  companyData?.userGroupName ||
                                                  '',
                                                exist:
                                                  !!userActiveRolePermissions?.find(
                                                    (roleItem: any) =>
                                                      roleItem?.name ===
                                                      companyData?.userGroupName
                                                  )
                                              },
                                              {
                                                roleName: 'pam',
                                                exist:
                                                  !!activeChildCompany?.lsPAMDetails
                                                    ?.map(
                                                      (item: any) =>
                                                        item?.userId
                                                    )
                                                    .includes(user?.ccoid)
                                              }
                                            ],
                                            'company',
                                            'updateOperationalAdmin'
                                          )
                                        }
                                        title={
                                          <label
                                            htmlFor="input-type-company-search-name"
                                            className="text-weight-700 required"
                                          >
                                            Primary Operational Admin
                                          </label>
                                        }
                                        open={openForEdit['operationalAdmin']}
                                        onOpenChange={(openStatus: boolean) =>
                                          updateEditFieldStatus(
                                            'operationalAdmin',
                                            openStatus
                                          )
                                        }
                                        companyType={companyData?.companyType}
                                        companyId={companyId}
                                        companyNodeId={
                                          activeChildCompany?.nodeId
                                        }
                                        pamList={
                                          activeChildCompany?.lsPAMDetails
                                            ?.length
                                            ? activeChildCompany?.lsPAMDetails?.map(
                                                (item: any) => item?.userId
                                              )
                                            : []
                                        }
                                        sponsorList={
                                          activeChildCompany?.lsSponsorAdmin
                                            ?.length
                                            ? activeChildCompany?.lsSponsorAdmin?.map(
                                                (item: any) => item?.sponsor
                                              )
                                            : []
                                        }
                                        value={{
                                          primaryOperationalAdmin:
                                            activeChildCompany?.lsOPAdmin
                                              ?.length
                                              ? activeChildCompany?.lsOPAdmin
                                                  ?.filter(
                                                    (item: any) => item?.primary
                                                  )
                                                  ?.map((item: any) => {
                                                    return {
                                                      value: item?.pamUserId,
                                                      label: item?.pamUserId,
                                                      userId: item?.pamUserId,
                                                      email: item?.email,
                                                      firstName:
                                                        item?.firstName,
                                                      lastName: item?.lastName,
                                                      terminated:
                                                        item?.terminated,
                                                      userRole: 'op'
                                                    };
                                                  })
                                              : [],
                                          secondaryOperationalAdmin:
                                            activeChildCompany?.lsOPAdmin
                                              ?.length
                                              ? activeChildCompany?.lsOPAdmin
                                                  ?.filter(
                                                    (item: any) =>
                                                      !item?.primary
                                                  )
                                                  ?.map((item: any) => {
                                                    return {
                                                      value: item?.pamUserId,
                                                      label: item?.pamUserId,
                                                      userId: item?.pamUserId,
                                                      email: item?.email,
                                                      firstName:
                                                        item?.firstName,
                                                      lastName: item?.lastName
                                                    };
                                                  })
                                              : []
                                        }}
                                        onSave={onOperationalAdminSave}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="form-group">
                                  <div className="form-group__text">
                                    <label
                                      htmlFor="input-type-company-search-name"
                                      className="text-weight-700 required"
                                    >
                                      Secondary Operational Admin
                                    </label>
                                    <div className="edit-form-value-holder">
                                      <UsersListValue
                                        users={
                                          activeChildCompany?.lsOPAdmin?.length
                                            ? activeChildCompany?.lsOPAdmin
                                                ?.filter(
                                                  (item: any) => !item?.primary
                                                )
                                                ?.map((item: any) => {
                                                  return {
                                                    value: item?.pamUserId,
                                                    label: item?.pamUserId,
                                                    userId: item?.pamUserId,
                                                    email: item?.email,
                                                    firstName: item?.firstName,
                                                    lastName: item?.lastName
                                                  };
                                                })
                                            : []
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!!mandatory && (
        <CompanyTypeMandatoryDialog
          mandatory={mandatory}
          userType={actualUserType}
          companyDetail={
            {
              companyType: companyData?.companyType,
              isOrgMandatory: companyData?.isOrgMandatory,
              isCountryMandatory: companyData?.isCountryMandatory,
              isCustomerMandatory: companyData?.isCustomerMandatory,
              isOrgShow: companyData?.isOrgShow,
              isCountryShow: companyData?.isCountryShow,
              isCustomerShow: companyData?.isCustomerShow,
              companyName: companyData?.companyName,
              organizations: formValue?.organizations,
              countries: formValue?.countries || [],
              customers: formValue?.customers || [],
              primaryOperationalAdmin:
                activeChildCompany?.lsOPAdmin
                  ?.filter(({ primary }: any) => {
                    return primary;
                  })
                  ?.map((item: any) => ({
                    ...item,
                    label: item.pamUserId,
                    value: item.pamUserId,
                    userId: item.pamUserId
                  })) || [],
              secondaryOperationalAdmin:
                activeChildCompany?.lsOPAdmin
                  ?.filter(({ primary }: any) => {
                    return !primary;
                  })
                  ?.map((item: any) => ({
                    ...item,
                    label: item.pamUserId,
                    value: item.pamUserId,
                    userId: item.pamUserId
                  })) || [],

              pamList: activeChildCompany?.lsPAMDetails?.length
                ? activeChildCompany?.lsPAMDetails?.map(
                    (item: any) => item?.userId
                  )
                : [],
              sponsorLis: activeChildCompany?.lsSponsorAdmin?.length
                ? activeChildCompany?.lsSponsorAdmin?.map(
                    (item: any) => item?.sponsor
                  )
                : [],
              companyNodeId: activeChildCompany?.nodeId
            } as any
          }
          open={!!mandatory}
          companyId={companyId}
          companyNodeId={activeChildCompany?.nodeId}
          updateFormValue={(formValue: any) => {
            setMandatory(null);
            setTimeout(() => {
              updateCompanyTypeFormValue(formValue);
            }, 5);
          }}
          onChange={(dialogStatus: boolean) => {}}
        />
      )}
    </div>
  );
};
export default CompanyEdit;
