//export const apiUrl = 'https://example.com/api/';

const commonConfig = {
  userInActivityTimeOut: 60, // in min
  agGridLicenseKey:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-056955}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Cisco_Systems,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_May_2025}____[v3]_[01]_MTc0NzYwOTIwMDAwMA==e955b3db186857b1797af460becd1814'
};

const config = {
  local: {
    api_url: '',
    oneAccessUrl: 'https://oneaccess-int.cisco.com',
    okta: {
      oktaDomain: 'int-id.cisco.com',
      oktaClientId: 'b1763f0c-42fc-4a01-abca-477b010fa4f5'
    },
    nonSCOVendorUserMandatoryRole: {
      name: 'stage.SCREG_Non-SCO_Contingent_Worker',
      displayName: 'SCREG Non-SCO Contingent Worker'
    }
  },
  dev: {
    api_url: '',
    oneAccessUrl: 'https://oneaccess-int.cisco.com',
    okta: {
      oktaDomain: 'int-id.cisco.com',
      oktaClientId: '0oaf105dljkEGAD4v1d7'
    },
    nonSCOVendorUserMandatoryRole: {
      name: 'stage.SCREG_Non-SCO_Contingent_Worker',
      displayName: 'SCREG Non-SCO Contingent Worker'
    }
  },
  stage: {
    api_url: '',
    oneAccessUrl: 'https://oneaccess-int.cisco.com',
    okta: {
      oktaDomain: 'int-id.cisco.com',
      oktaClientId: '0oaf107gt1CsLxHwg1d7'
    },
    nonSCOVendorUserMandatoryRole: {
      name: 'stage.SCREG_Non-SCO_Contingent_Worker',
      displayName: 'SCREG Non-SCO Contingent Worker'
    }
  },
  b2x: {
    api_url: '',
    oneAccessUrl: 'https://oneaccess.cisco.com',
    okta: {
      oktaDomain: 'id.cisco.com',
      oktaClientId: 'e8f8ac7f-2bd2-42f2-90b4-f09e4c878e8c'
    },
    nonSCOVendorUserMandatoryRole: {
      name: 'SCREG_Non-SCO_Contingent_Worker',
      displayName: 'SCREG Non-SCO Contingent Worker'
    }
  },
  prod: {
    api_url: '',
    oneAccessUrl: 'https://oneaccess.cisco.com',
    okta: {
      oktaDomain: 'id.cisco.com',
      oktaClientId: '868706ac-ba17-4532-a845-97c2674030f7'
    },
    nonSCOVendorUserMandatoryRole: {
      name: 'SCREG_Non-SCO_Contingent_Worker',
      displayName: 'SCREG Non-SCO Contingent Worker'
    }
  }
};

const host = window.location.hostname;
let environment = '';
switch (true) {
  case host.includes('dev.cloudapps.cisco.com'):
    environment = 'dev';
    break;

  case host.includes('stage.cloudapps.cisco.com'):
    environment = 'stage';
    break;

  case host.includes('b2x.cloudapps.cisco.com'):
    environment = 'b2x';
    break;

  case host.includes('screg-ng.cloudapps.cisco.com'):
    environment = 'prod';
    break;
  default:
    environment = 'local';
    break;
}

const envConfig = { ...(config[environment] || {}), ...commonConfig };
export { envConfig as config };
