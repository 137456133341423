import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash/cloneDeep';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { fetchInvitations, fetchProfile } from '../../../actions';
import Loader from '../../atoms/Loader';
import UserInvitationApproval from '../../organisms/UserInvitationApproval';
import InvitationDetailsDialog from '../InvitationDetailsDialog';
import RefreshButton from '../../atoms/RefreshButton';

const dateFilterParams = {
  // provide comparator function
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    const valueDate = new Date(cellValue);

    const dateAsString = valueDate
      ? moment(valueDate).format('DD/MM/YYYY')
      : null;

    if (dateAsString == null) {
      return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('/');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

export interface InvitationsTableProps {
  title?: string;
}

const InvitationsTable = ({ title }: InvitationsTableProps) => {
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState<any>(null);

  const [rows, setRows] = React.useState<any>();
  const [columns, setColumns] = React.useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState<any>(false);
  const [viewDialogOpen, setViewDialogOpen] = useState<any>(false);
  const [invitation, setInvitation] = useState<any>(undefined);

  const [search, setSearch] = useState(null);
  const [finalSearch, setFinalSearch] = useState<string | null>(null);
  const [action, setAction] = useState<string | null>(null);

  const invitationsData = useSelector((state: any) => state.invitation);

  const {
    getInvitationsListStatus,
    invitations,
    getInvitationListErrorMessage
  } = invitationsData;

  useEffect(() => {
    if (gridApi) {
      if (getInvitationsListStatus === 'SUCCESS' && rows && rows.length === 0) {
        gridApi.showNoRowsOverlay();
      } else if (getInvitationsListStatus !== 'PENDING') {
        gridApi.hideOverlay();
      }
    }
  }, [invitations, getInvitationsListStatus, gridApi]);

  useEffect(() => {
    dispatch(fetchInvitations());
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'GET_INVITATION_LIST_RESET'
      });
    };
  }, []);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const debouncedSearchSet = useCallback(
    debounce((nextValue) => setFinalSearch(nextValue), 800),
    []
  );

  useEffect(() => {
    const columnsList = [
      {
        headerName: 'Company Name',
        field: 'companyName',
        filter: true,
        suppressHeaderMenuButton: true,
        headerTooltip: 'Company Name',
        tooltipField: 'companyName',
        pinned: 'left',
        width: 400
      },
      {
        headerName: 'Nominated Role',
        field: 'notificationName',
        filter: true,
        suppressHeaderMenuButton: true,
        headerTooltip: 'Nominated Role',
        tooltipField: 'notificationName',
        minWidth: 150,
        flex: 1,
        cellRenderer: (params: any) => {
          const role = params?.value;

          switch (true) {
            case role === 'COMPANY_ONBOARD_SPONSOR_NOTIFICATION':
              return 'Sponsor';

            case role === 'COMPANY_ONBOARD_PAM_NOTIFICATION':
              return 'Partner Account Managers';

            case role === 'COMPANY_ONBOARD_PA_NOTIFICATION':
              return 'Partner Administrators';

            case role === 'COMPANY_ONBOARD_OP_NOTIFICATION':
              return 'Operational Admin';

            case role === 'COMPANY_ONBOARDED_EXISTING_PA_NOTIFICATION':
              return 'Partner Administrators';

            default:
              return role;
          }
        }
      },
      {
        headerName: 'Invited By',
        field: 'createdBy',
        filter: true,
        suppressHeaderMenuButton: true,
        headerTooltip: 'Invited By',
        tooltipField: 'createdBy',
        minWidth: 150,
        flex: 1
      },
      {
        headerName: 'Date',
        field: 'creationDate',
        filter: 'agDateColumnFilter',
        suppressHeaderMenuButton: true,
        headerTooltip: 'Request Date',
        cellRenderer: (params: any) => {
          return params?.value
            ? moment(new Date(params?.value)).format('MM-DD-YYYY')
            : '-';
        },
        filterParams: dateFilterParams,
        minWidth: 130,
        flex: 1
      },
      {
        headerName: 'Status',
        field: 'approvalStatus',
        filter: true,
        suppressHeaderMenuButton: true,
        headerTooltip: 'Status',
        minWidth: 140,
        flex: 1,
        cellRenderer: (params: any) => {
          const value = params?.value?.toLowerCase();
          switch (value) {
            case 'pending':
              return (
                <span className="label label--info label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'approved':
              return (
                <span className="label label--success label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'cancelled':
              return (
                <span className="label label--warning label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'denied':
              return (
                <span className="label label--danger label--small label--bordered">
                  {params?.value}
                </span>
              );

            default:
              return (
                <span className="label label--light label--small label--bordered">
                  {params?.value}
                </span>
              );
          }
        }
      },

      {
        headerName: 'Action',
        field: 'action',
        headerTooltip: 'Action',
        width: 180,
        pinned: 'right',
        suppressHeaderMenuButton: true,
        cellRenderer: (params: any) => {
          return (
            <div>
              <a
                onClick={() => {
                  onClickViewDialogOpen(params.data);
                }}
              >
                View
              </a>{' '}
              |{' '}
              <a
                onClick={() => {
                  onClickRequestDialogOpen(params.data, 'ACCEPT');
                }}
              >
                Accept
              </a>{' '}
              |{' '}
              <a
                onClick={() => {
                  onClickRequestDialogOpen(params.data, 'DENY');
                }}
              >
                Deny
              </a>
            </div>
          );
        }
      }
    ].map((item: any) => {
      let itemTemp: any = item;
      itemTemp.resizable = true;
      // itemTemp.floatingFilter = true;

      itemTemp.columnChooserParams = {
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: false,
        suppressColumnFilter: false
      };

      itemTemp.menuTabs = ['filterMenuTab'];

      if (item.field !== 'action') {
        itemTemp.sortable = true;
        // itemTemp.flex = 1;
      } else {
        itemTemp.sortable = false;
      }

      return itemTemp;
    });
    setColumns(columnsList);
  }, [gridApi]);

  useEffect(() => {
    const filterableColumn = columns
      .filter(({ field }: any) => field !== 'action')
      .map(({ field }: any) => field);
    const requestTemp: any = cloneDeep(invitations || []);
    const res = requestTemp
      .map((item: any) => {
        let itemTemp = item;
        itemTemp.fullName = item?.firstName + ' ' + item?.lastName;

        return itemTemp;
      })
      .filter((item: any) => {
        if (!finalSearch) {
          return true;
        }
        let valid = false;
        Object.keys(item)
          .filter((key: string) => filterableColumn.includes(key))
          .forEach((key) => {
            if (key === 'createdDate' || key === 'expiryDate') {
              const itemValue = item[key]
                ? moment(new Date(item[key])).format('MM-DD-YYYY')
                : '-';
              if (
                itemValue
                  .toString()
                  .toLowerCase()
                  .includes(finalSearch?.toLowerCase())
              ) {
                valid = true;
              }
            } else if (
              item[key]
                .toString()
                .toLowerCase()
                .includes(finalSearch?.toLowerCase())
            ) {
              valid = true;
            }
          });
        return valid;
      });

    if (getInvitationsListStatus === 'SUCCESS') {
      setRows(res);
    }
  }, [columns, finalSearch, invitations, getInvitationsListStatus]);

  const onSearchChange = (e: any) => {
    const value = e.target.value;
    setSearch(value.toLowerCase());
    debouncedSearchSet(value.toLowerCase());
  };

  const onClickViewDialogOpen = (requestRow: any) => {
    setInvitation(requestRow);
    setViewDialogOpen(true);
  };

  const onClickRequestDialogOpen = (requestRow: any, action?: string) => {
    setAction(action || null);
    setInvitation(requestRow);
    setDialogOpen(true);
  };

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const getOverlayNoRowsTemplate = () => {
    return 'No pending invitations found';
  };

  const onInvitationUpdate = () => {
    dispatch(fetchInvitations());
    dispatch(
      fetchProfile({
        userProfile: false
      })
    );
  };

  const refresh = () => {
    dispatch(fetchInvitations());
  };

  return (
    <div className="">
      <div
        className="half-padding ag-table-title"
        style={{ borderBottom: 'none' }}
      >
        <div className="table-card-header d-flex align-items-center">
          <div className="table-card-title flex-grow-1">
            {title && (
              <div className="flex-center-vertical">
                <h5 className="display-5">{title}</h5>
              </div>
            )}
          </div>
          <div className="flex-shrink-0  d-flex">
            <RefreshButton
              onClick={refresh}
              loading={getInvitationsListStatus == 'PENDING'}
            />
            <div className="base-margin-left">
              <div className="form-group input--icon">
                <div className="form-group__text">
                  <input
                    id="input-type-search"
                    value={search || ''}
                    onChange={onSearchChange}
                    type="search"
                    className="table-search-input"
                  />
                  <button type="button" className="link">
                    <span className="icon-search"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`ag-theme-alpine `}
        style={{ height: 470, width: '100%' }}
      >
        <AgGridReact
          domLayout={'normal'}
          ref={gridRef}
          rowData={rows}
          columnDefs={columns}
          gridOptions={{ suppressContextMenu: true }}
          pagination={true}
          paginationPageSize={10}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
          loadingOverlayComponent={Loader}
          enableCellTextSelection={true}
          suppressCellFocus={true}
          getRowStyle={getRowStyle}
          headerHeight={38}
          rowHeight={38}
          loading={getInvitationsListStatus === 'PENDING'}
          paginationPageSizeSelector={false}
        ></AgGridReact>
      </div>

      <UserInvitationApproval
        invitation={invitation}
        open={dialogOpen}
        action={action || ''}
        onChange={(dialogStatus: boolean) => {
          setDialogOpen(dialogStatus);
        }}
        onSuccess={onInvitationUpdate}
      />
      <InvitationDetailsDialog
        invitation={invitation}
        open={viewDialogOpen}
        onChange={(dialogStatus: boolean) => {
          setViewDialogOpen(dialogStatus);
        }}
        onSuccess={onInvitationUpdate}
      />
    </div>
  );
};
export default InvitationsTable;
