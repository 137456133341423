import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchRevalidationDetails = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/company/revalidation/v1/company-account-revalidation/details`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching Revalidation Details ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const fetchRevalidationList = async (body: any = {}) => {
  try {
    const res: any = await httpUtil.post(
      `/api/company/revalidation/v1/get/companyRevalidate`,
      body
    );
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while fetching company reviews ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};

export const companyRevalidationBulkUpdate = async (
  taskName: string,
  action: string,
  body: any = {}
) => {
  try {
    let apiUrl = '';
    if (taskName === 'OP_REVIEW' && action === 'APPROVE') {
      apiUrl = '/api/company/revalidation/v1/bulk/OPReview/approval';
    }

    if (taskName === 'OP_REVIEW' && action === 'DENY') {
      apiUrl = '/api/company/revalidation/v1/bulk/OPReview/denial';
    }

    if (taskName === 'PAM_REVIEW' && action === 'APPROVE') {
      apiUrl = '/api/company/revalidation/v1/bulk/PAMReview/approval';
    }

    if (taskName === 'PAM_REVIEW' && action === 'DENY') {
      apiUrl = '/api/company/revalidation/v1/bulk/PAMReview/denial';
    }

    const res: any = await httpUtil.post(apiUrl, body);
    return res;
  } catch (error: any) {
    const errorMessage = `An error occurred while updating company review  ${
      error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
    }`;
    notify(errorMessage, 'error');
    return Promise.reject(error);
  }
};
